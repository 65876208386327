import React, {Fragment, useEffect, useGlobal, useState} from "reactn";
import {Collapse, DatePicker, Divider, Empty, Icon, List, Select, Tag} from "antd";
import moment from "moment";
import {firestore} from "../../firebase";
import {querySnapshotToArray} from "../../utils";
import {Button, FiltersGrid, ListHeader, ListRow, NoData, PanelBody, PanelHeader} from "../../components/complexList";
import {useHistory} from "react-router";

export const Refunds = () => {
    const [globalUser] = useGlobal("user");
    const [globalTics] = useGlobal("tics");
    const [globalTicId, setGlobalTicId] = useGlobal("ticId");
    const [refundsCreateAt, setRefundsCreateAt] = useState(moment());
    const [ticId, setTicId] = useState(undefined);
    const [refunds, setRefunds] = useState([]);

    const history = useHistory();

    useEffect(() => {
        if (ticId) setGlobalTicId(ticId);
    }, [ticId, setGlobalTicId]);

    useEffect(() => {
        const fetchRefunds = async (createAt, ticId) => {
            const startOfDay = createAt.startOf("day").toDate();
            const endOfDay = createAt.endOf("day").toDate();

            const refundsQuerySnapshot = await firestore
                .collection("refunds")
                .where("createAt", ">=", startOfDay)
                .where("createAt", "<=", endOfDay)
                .where("tic.id", "==", ticId)
                .get();

            return setRefunds(querySnapshotToArray(refundsQuerySnapshot));
        };

        if (refundsCreateAt && globalTicId) fetchRefunds(refundsCreateAt, globalTicId);
    }, [refundsCreateAt, globalTicId]);

    return (
        <Fragment>
            <Button type="primary"
                    disabled={!globalTicId}
                    onClick={() => history.push(`/tics/${globalTicId}/refunds/new`)}>
                <Icon type="plus"/>
                <p>NEW REFUND</p>
            </Button>
            <FiltersGrid>
                <DatePicker value={refundsCreateAt}
                            onChange={setRefundsCreateAt}
                            format="DD/MM/YYYY"/>
                <Select value={globalTicId || undefined}
                        placeholder="Select tic"
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        onSelect={setTicId}
                        showSearch>
                    {
                        globalTics
                            .filter(globalTic => globalUser.roleCode === "group_admin" ? globalTic.groupId === globalUser.groupId : true)
                            .map(globalTic =>
                                <Select.Option key={globalTic.id}
                                               value={globalTic.id}>
                                    {globalTic.name}
                                </Select.Option>
                            )
                    }
                </Select>
            </FiltersGrid>
            {
                refunds.length
                    ? (
                        <Collapse accordion>
                            {
                                refunds.map(refund =>
                                    <Collapse.Panel key={refund.id}
                                                    header={
                                                        <PanelHeader>
                                                            <p>Refund by {refund.user.email}</p>
                                                            <Divider type="vertical"/>
                                                            <p>{moment(refund.createAt.toDate()).format("h:mm a")}</p>
                                                            <Divider type="vertical"/>
                                                            <p>Nº People {refund.numberOfPerson}</p>
                                                        </PanelHeader>
                                                    }>
                                        <PanelBody>
                                                <span>
                                                    <h3>Customer Refunds</h3>
                                                    <p>{refund.firstName} {refund.lastName}</p>
                                                </span>
                                            <span>
                                                    <h3>Refund Price</h3>
                                                    <p>{refund.refundCurrency} {refund.refundPrice}</p>
                                                </span>
                                            <span>
                                                    <h3>Note</h3>
                                                    <p>{refund.noteRefund}</p>
                                                </span>
                                        </PanelBody>
                                        <List bordered
                                              header={
                                                  <ListHeader>
                                                      <p>Company</p>
                                                      <p>Tours</p>
                                                  </ListHeader>
                                              }>
                                            {
                                                refund.tours && refund.tours.length
                                                    ?
                                                    refund.tours.map((refundTour, index) =>
                                                        <List.Item key={index}>
                                                            <ListRow>
                                                                <span>
                                                                    <Tag>{refundTour.company.name}</Tag>
                                                                </span>
                                                                <span>
                                                                    <Tag>{refundTour.tour.name}</Tag>
                                                                </span>
                                                            </ListRow>
                                                        </List.Item>
                                                    )
                                                    :
                                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                                            }
                                        </List>
                                    </Collapse.Panel>
                                )
                            }
                        </Collapse>
                    )
                    : (
                        <NoData>
                            No data
                        </NoData>
                    )

            }
        </Fragment>
    )
};
