import React from "react";
import styled from "styled-components";
import {Card as AntCard, Select as AntSelect} from "antd";
import {get, orderBy} from "lodash";
import titleize from "titleize";

export {List} from "antd";

export const Description = styled.label`
    color: #1890ff;
    cursor: pointer;
`;

export const DeleteButton = styled.label`
    font-size: 24px;
    color: #fe008f;
    cursor: pointer;
`;

export const Card = styled(AntCard)`
  .ant-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 130px;
    
    p {
      margin-top: 0.5rem;
      margin-bottom: 0;
      font-size: 14px;
      text-align: center;
    }
    
    i {
      font-size: 40px;
    }
  }
`;

export const ListFilter = props => (
    <Select placeholder={props.placeholder}
            width={props.width}
            onSelect={props.onSelect}
            defaultValue={props.defaultValue}
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            showSearch>
        {
            orderBy(get(props, "filterOptions", []), filterOption => titleize(filterOption[get(props, "filterOptionName", "id")]), get(props, "filterOptionOrderBy", ["id", "asc"]))
                .map(filterOption =>
                    <Select.Option key={filterOption[get(props, "filterOptionValue", "id")]}
                                   value={filterOption[get(props, "filterOptionValue", "id")]}>
                        {filterOption[get(props, "filterOptionName", "id")]}
                    </Select.Option>
                )
        }
    </Select>
);

const Select = styled(AntSelect)`
  width: ${props => get(props, "width", "100%")};
  margin-top: 1rem;
  margin-bottom: 1rem;
`;
