import React, {useEffect, useGlobal, useState} from "reactn";
import {auth, firestore} from "../firebase";

export const withAuthentication = Component =>
    () => {
        const [, setGlobalUser] = useGlobal("user");
        const [globalIsLoadingUser, setGlobalIsLoadingUser] = useGlobal("isLoadingUser");
        const [isLoadingAuth, setIsLoadingAuth] = useState(true);

        useEffect(() => {
            const fetchUserFromFirestore = async authUser => {
                try {
                    const userSnapshot = await firestore
                        .collection("users")
                        .doc(authUser.uid)
                        .get();
                    return userSnapshot.data();
                } catch (error) {
                    console.log(error);
                }
            };

            const unsubscribeAuthStateChanged = auth.onAuthStateChanged(async authUser => {
                if (authUser) {
                    const user = await fetchUserFromFirestore(authUser);

                    await setGlobalUser(user);
                    await setGlobalIsLoadingUser(false);

                    return setIsLoadingAuth(false);
                }

                if (!authUser && !globalIsLoadingUser) {
                    await setGlobalUser(null);

                    return setIsLoadingAuth(false);
                }
            });
            return () => unsubscribeAuthStateChanged();
        }, [globalIsLoadingUser, setGlobalIsLoadingUser, setGlobalUser]);

        return isLoadingAuth
            ? null
            : <Component/>;

    };
