import React, {Fragment, useGlobal, useMemo, useState} from "reactn";
import styled from "styled-components";
import {Button, DatePicker as AntDatePicker, Divider, Icon, Input, Modal, Select, Table, Tag} from "antd";
import {Controller, useForm} from "react-hook-form";
import {array, number, object, string} from "yup";
import {assign, get, orderBy} from "lodash";
import {useHistory, useParams} from "react-router";
import moment from "moment";
import {Countries} from "../../../../utils/countries";
import {HowDidYouGetToBarranco} from "../../../../utils/howDidYouGetToBarranco";
import {firestore} from "../../../../firebase";
import {AddSale} from "./AddSale";

export const ClientForm = () => {
    const [globalUser] = useGlobal("user");
    const [globalTics] = useGlobal("tics");
    const [globalInformationTypes] = useGlobal("informationTypes");
    const [clientForm, setClientForm] = useState(null);
    const [sales, setSales] = useState([]);
    const [currentSale, setCurrentSale] = useState(null);
    const [isAddingSale, setIsAddingSale] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [groupId, setGroupId] = useState(null);

    const {ticId, clientFormId} = useParams();
    const history = useHistory();

    const validationSchema = object().shape({
        country: string().required(),
        entryDate: string().required(),
        note: string(),
        numberOfPeople: number().integer().positive().required(),
        typesInformation: array().required(),
        age: string().nullable(),
        howDidYouGetToBarranco: string()
    });

    const {control, errors, watch, handleSubmit} = useForm({
        validationSchema,
        reValidateMode: "onSubmit"
    });

    useMemo(() => {
        const fetchClientForm = async () => {
            if (clientFormId === "new") {
                const clientFormRef = firestore.collection("clientForms").doc();

                return setClientForm({id: clientFormRef.id, createAt: new Date()});
            }
            const clientFormDocumentSnapshot = await firestore.collection("clientForms").doc(clientFormId).get();

            if (clientFormDocumentSnapshot.exists) {
                setSales(clientFormDocumentSnapshot.data().sales);
                return setClientForm({
                    ...clientFormDocumentSnapshot.data(),
                    id: clientFormDocumentSnapshot.id
                });
            }

            return history.goBack();
        };

        fetchClientForm();
    }, [clientFormId, history]);

    useMemo(() => {
        const currentTic = globalTics.find(globalTic => globalTic.id === ticId);
        setGroupId(currentTic.groupId);
    }, [ticId, globalTics]);

    const addSale = sale => setSales(prevSales => [...prevSales, sale]);

    const deleteSale = saleIndex => setSales(prevSales => prevSales.filter((mappedSale, index) => index !== saleIndex));

    const editSale = sale => setSales(prevSales => [...prevSales.filter(prevSale => prevSale.id !== sale.id), sale]);

    const onSubmit = async data => {
        if (!isAddingSale) {
            setIsSubmitting(true);

            await firestore
                .collection("clientForms")
                .doc(clientForm.id)
                .set(mappedClientForm(data), {merge: true});

            return history.goBack();
        }
    };

    const deleteSaleModal = saleIndex => Modal
        .confirm({
            title: "Are you sure delete?",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk: () => deleteSale(saleIndex)
        });

    const mappedClientForm = data => {
        const tic = globalTics.find(globalTic => globalTic.id === ticId);
        const mappedTypesInformation = data.typesInformation
            .map(typeInformation => globalInformationTypes
                .find(globalInformationType => globalInformationType.id === typeInformation)
            );

        return assign({}, clientForm, data, {
            updateAt: new Date(),
            user: get(clientForm, "user", null) || globalUser,
            tic,
            sales,
            typesInformation: mappedTypesInformation,
            entryDate: moment(data.entryDate).toDate(),
            status: "confirmed"
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <AddSale tic={globalTics.find(globalTic => globalTic.id === ticId)}
                     addSale={addSale}
                     editSale={editSale}
                     sale={currentSale}
                     onOpen={() => setIsAddingSale(true)}
                     onClose={() => setIsAddingSale(false)}/>
            <br/>
            {
                sales.length > 0 &&
                <Table pagination={false}
                       dataSource={orderBy(sales.map(sale => ({
                           ...sale,
                           key: sale.id,
                           fullName: `${sale.firstName} ${sale.lastName}`
                       })), ["createAt", "asc"])}

                       columns={[
                           {
                               title: "Full Name",
                               dataIndex: "fullName",
                               key: "fullName",
                           },
                           {
                               title: "Total Price",
                               dataIndex: "totalPrice",
                               key: "totalPrice",
                               render: totalPrice => `${totalPrice.currency} ${totalPrice.total.toFixed(2)}`
                           },
                           {
                               title: "Discount",
                               dataIndex: "discount",
                               render: discount => discount ? `${discount.currency} ${discount.total.toFixed(2)}` : null
                           },
                           {
                               title: "Card",
                               dataIndex: "card",
                               render: card => card ? `${card.currency} ${card.total.toFixed(2)}` : null
                           },
                           {
                               title: "Paid",
                               dataIndex: "paid",
                               key: "paid",
                               render: paid => `S/ ${paid.total.toFixed(2)}`
                           },
                           {
                               title: "Paid",
                               dataIndex: "paidDollars",
                               key: "paidDollars",
                               render: paidDollars => `$ ${paidDollars.total.toFixed(2)}`
                           },
                           {
                               title: "Tours",
                               dataIndex: "tours",
                               key: "tours",
                               render: tours =>
                                   tours.map((tour, index) =>
                                       <Tag key={index}
                                            color="blue"
                                            style={{display: "block", width: "fit-content"}}>
                                           {tour.tour.name}
                                       </Tag>
                                   )
                           },
                           {
                               title: "Action",
                               dataIndex: "id",
                               key: "id",
                               render: (id, value, index) => {
                                   return (
                                       <Fragment>
                                           <Icon type="delete"
                                                 onClick={() => deleteSaleModal(index)}
                                                 style={{fontSize: "20px", color: "red"}}/>
                                           <Divider type="vertical"/>
                                           <Icon type="edit"
                                                 onClick={() => setCurrentSale(value)}
                                                 style={{fontSize: "20px", color: "#1890ff"}}/>
                                       </Fragment>
                                   )
                               }
                           }
                       ]}/>
            }
            <Label required>
                Time
            </Label>
            <Controller
                key={`entryDate-${moment(get(clientForm, "entryDate", moment()).toDate()).format("DD/MM/YYYY HH:mm:ss")}`}
                name="entryDate"
                defaultValue={moment(get(clientForm, "entryDate", watch("entryDate") || moment()).toDate())}
                control={control}
                as={
                    <DatePicker showTime/>
                }/>
            {errors.entryDate && <Error>{errors.entryDate.message}</Error>}
            <Label required>
                Nationality
            </Label>
            <Controller key={`country-${get(clientForm, "id", null)}`}
                        name="country"
                        defaultValue={get(clientForm, "country", undefined)}
                        control={control}
                        as={
                            <Select showSearch
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                {
                                    Countries.map(country =>
                                        <Select.Option key={country.code}
                                                       value={country.code}>
                                            {country.countryName}
                                        </Select.Option>
                                    )
                                }
                            </Select>
                        }/>
            {errors.country && <Error>{errors.country.message}</Error>}
            <Label required>
                Number of people
            </Label>
            <Controller key={`numberOfPeople-${get(clientForm, "id", null)}`}
                        name="numberOfPeople"
                        defaultValue={get(clientForm, "numberOfPeople", undefined)}
                        control={control}
                        as={
                            <Input type="number"/>
                        }/>
            {errors.numberOfPeople && <Error>{errors.numberOfPeople.message}</Error>}
            <Label required>
                Type of information
            </Label>
            <Controller key={`typesInformation-${get(clientForm, "id", null)}`}
                        name="typesInformation"
                        defaultValue={get(clientForm, "typesInformation", []).map(informationType => informationType.id)}
                        control={control}
                        as={
                            <Select showSearch
                                    mode="multiple"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                {
                                    globalInformationTypes
                                        .filter(globalInformationType => globalInformationType.groupId === groupId)
                                        .map(globalInformationType =>
                                            <Select.Option key={globalInformationType.id}
                                                           value={globalInformationType.id}>
                                                {globalInformationType.name}
                                            </Select.Option>
                                        )
                                }
                            </Select>
                        }/>
            {errors.typesInformation && <Error>{errors.typesInformation.message}</Error>}
            <Label>
                Note
            </Label>
            <Controller key={`note-${get(clientForm, "id", null)}`}
                        name="note"
                        defaultValue={get(clientForm, "note", "")}
                        control={control}
                        as={
                            <Input.TextArea autoSize={{minRows: 4}}/>
                        }/>
            {errors.note && <Error>{errors.note.message}</Error>}
            <Divider/>
            <h1>Info for Barranco</h1>
            <Label>
                Age
            </Label>
            <Controller key={`age-${get(clientForm, "id", null)}`}
                        name="age"
                        defaultValue={get(clientForm, "age", undefined)}
                        control={control}
                        as={
                            <Select showSearch
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                {
                                    ["-18", "18 - 27", "28 - 37", "38 - 49", "+50"].map(age =>
                                        <Select.Option key={age}
                                                       value={age}>
                                            {age}
                                        </Select.Option>
                                    )
                                }
                            </Select>
                        }/>
            {errors.age && <Error>{errors.age.message}</Error>}
            <Label>
                How did you get to Barranco?
            </Label>
            <Controller key={`howDidYouGetToBarranco-${get(clientForm, "id", null)}`}
                        name="howDidYouGetToBarranco"
                        defaultValue={get(clientForm, "howDidYouGetToBarranco", undefined)}
                        control={control}
                        as={
                            <Select showSearch
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                {
                                    HowDidYouGetToBarranco.map(howDidYouGetToBarranco =>
                                        <Select.Option key={howDidYouGetToBarranco.code}
                                                       value={howDidYouGetToBarranco.code}>
                                            {howDidYouGetToBarranco.description}
                                        </Select.Option>
                                    )
                                }
                            </Select>
                        }/>
            {errors.howDidYouGetToBarranco && <Error>{errors.howDidYouGetToBarranco.message}</Error>}
            <Button block
                    htmlType="submit"
                    loading={isSubmitting}
                    style={{marginTop: "2rem"}}
                    type="primary">
                SUBMIT
            </Button>
        </form>
    )
};

const Label = styled.label`
  margin: 1rem 0;
  display: block;
  ${props => props.required && `
    :before {
        display: inline-block;
        margin-right: 4px;
        color: #f5222d;
        font-size: 14px;
        font-family: SimSun,sans-serif;
        line-height: 1;
        content: "*";
    }
  `}
`;

const Error = styled.p`
  color: #f5222d;
  margin-bottom: 0;
`;

const DatePicker = styled(AntDatePicker)`
  width: 100%;
`;
