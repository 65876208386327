import React, {useEffect, useState} from "reactn";
import {firestore, version} from "../firebase";
import {querySnapshotToArray} from "../utils";
import {withConfiguration} from "../hoc";
import App from "../App";
import {UpdateVersion} from "./UpdateVersion";

const CheckVersion = () => {
    const [isLastVersion, setIsLastVersion] = useState(true);

    useEffect(() => {
        const unsubscribeVersion = firestore
            .collection("versions")
            .onSnapshot(snapshot => {
                const versionsArray = querySnapshotToArray(snapshot);
                const lastVersion = versionsArray[0].versionNumber;

                setIsLastVersion(version === lastVersion);
            });
        return () => unsubscribeVersion();
    });

    return isLastVersion
        ? <App/>
        : <UpdateVersion/>
};

export default withConfiguration(CheckVersion);
