import React, {useEffect, useGlobal, useState} from "reactn";
import styled from "styled-components";
import {Button, Divider as AntDivider, Table as AntTable} from "antd";
import titleize from "titleize";
import {useHistory, useParams} from "react-router";
import moment from "moment";
import {firestore} from "../../../../../../../../firebase";
import {querySnapshotToArray} from "../../../../../../../../utils";

export const PrintFreeWalkingTourForms = () => {
    const {groupId, placeId, entryDate} = useParams();
    const [globalPlaces] = useGlobal("places");
    const [freeWalkingTourForms, setFreeWalkingTourForms] = useState([]);
    const [place, setPlace] = useState(undefined);

    const history = useHistory();

    useEffect(() => {
        const fetchFreeWalkingTourForms = async () => {
            const freeWalkingTourFormsQuerySnapshot = await firestore
                .collection("freeWalkingTourForms")
                .where("entryDate", "==", entryDate)
                .where("groupId", "==", groupId)
                .where("placeId", "==", placeId)
                .get();

            const freeWalkingTourFormsArray = querySnapshotToArray(freeWalkingTourFormsQuerySnapshot);

            if (!freeWalkingTourFormsArray.length) return history.goBack();

            setFreeWalkingTourForms(freeWalkingTourFormsArray
                .map(freeWalkingTourForm => ({
                        firstName: titleize(freeWalkingTourForm.firstName),
                        lastName: titleize(freeWalkingTourForm.lastName),
                        email: freeWalkingTourForm.email,
                        language: titleize(freeWalkingTourForm.language),
                        key: freeWalkingTourForm.id
                    })
                )
            );
        };

        fetchFreeWalkingTourForms();
    }, [entryDate, history, placeId, groupId]);

    useEffect(() => {
        if (placeId) {
            setPlace(globalPlaces.find(place => place.id === placeId))
        }
    }, [placeId, setPlace]);

    useEffect(() => {
        if (freeWalkingTourForms.length) window.print();
    }, [freeWalkingTourForms]);

    return (
        <Content>
            <Header>
                <h1>Free Walking Tours</h1>
                <Button type="primary"
                        onClick={() => history.goBack()}>
                    BACK
                </Button>
            </Header>
            <Divider/>
            <Header>
                <h1>{titleize(place?.name || "")}</h1>
                <h3>Day: <b>{moment(entryDate, "YYYY-MM-DD").format("DD/MM/YYYY")}</b></h3>
            </Header>
            <Table dataSource={freeWalkingTourForms}
                   pagination={false}
                   columns={[
                       {
                           title: "First Name",
                           dataIndex: "firstName",
                           key: "firstName"

                       },
                       {
                           title: "Last Name",
                           dataIndex: "lastName",
                           key: "lastName"
                       },
                       {
                           title: "Email",
                           dataIndex: "email",
                           key: "email"
                       },
                       {
                           title: "Language",
                           dataIndex: "language",
                           key: "language"
                       }
                   ]}/>
        </Content>
    )
};

const Content = styled.section`
  padding: 20px;
  
  @media print {
    padding: 0;
  }
  
  h1 {
    font-weight: bold;
    margin-bottom: 0;
  }
  
  h3 {
    margin-bottom: 0;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  
  @media print {
    button {
      display: none;
    }
  }
`;

const Table = styled(AntTable)`
  margin-top: 1rem;
  
  th, td {
    padding: 0 0.5rem !important;
  }
`;

const Divider = styled(AntDivider)`
  @media print {
    margin: 0.5rem 0;
  }
`;
