import React, {Fragment, useGlobal, useState} from "reactn";
import {Icon, Modal} from "antd";
import {firestore} from "../../firebase";
import {Card, List} from "../../components/list";
import {Group} from "./Group";

export const Groups = () => {
    const [globalGroups] = useGlobal("groups");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [groupId, setGroupId] = useState(null);

    const deleteGroup = groupId => Modal
        .confirm({
            title: "Are you sure delete?",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk: () => {
                firestore
                    .collection("groups")
                    .doc(groupId)
                    .delete();
            }
        });

    const openGroupModal = groupId => {
        setIsModalVisible(true);
        setGroupId(groupId);
    };

    const closeGroupModal = () => {
        setIsModalVisible(false);
        setGroupId(null);
    };

    return (
        <Fragment>
            <Card hoverable
                  onClick={() => openGroupModal()}>
                <Icon type="plus"/>
                <p>ADD GROUP</p>
            </Card>
            <br/>
            <List dataSource={globalGroups}
                  grid={{
                      gutter: 8,
                      xs: 1,
                      md: 2,
                      lg: 4,
                      xl: 6
                  }}
                  renderItem={(group, index) => (
                      <List.Item key={index}>
                          <Card hoverable
                                actions={[
                                    <Icon type="edit"
                                          onClick={() => openGroupModal(group.id)}/>,
                                    <Icon type="delete"
                                          onClick={() => deleteGroup(group.id)}/>
                                ]}>
                              <Icon type="cluster"/>
                              <p>
                                  {group.groupName}
                              </p>
                          </Card>
                      </List.Item>
                  )}/>
            {
                isModalVisible &&
                <Group groupId={groupId}
                       onClose={closeGroupModal}/>
            }
        </Fragment>
    )
};
