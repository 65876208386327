import React, {Fragment, useEffect, useGlobal, useState} from "reactn";
import {Icon, Modal} from "antd";
import {firestore} from "../../firebase";
import {Card, List, ListFilter} from "../../components/list";
import {Tic} from "./Tic";

export const Tics = () => {
    const [globalUser] = useGlobal("user");
    const [globalTics] = useGlobal("tics");
    const [globalGroups] = useGlobal("groups");
    const [globalGroupId, setGlobalGroupId] = useGlobal("groupId");
    const [groupId, setGroupId] = useState(globalUser.groupId);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [ticId, setTicId] = useState(null);

    useEffect(() => {
        if (groupId) setGlobalGroupId(groupId);
    }, [groupId, setGlobalGroupId]);

    const deleteTic = ticId => Modal
        .confirm({
            title: "Are you sure delete?",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk: () => {
                firestore
                    .collection("tics")
                    .doc(ticId)
                    .delete();
            }
        });

    const openTicModal = ticId => {
        setIsModalVisible(true);
        setTicId(ticId);
    };

    const closeTicModal = () => {
        setIsModalVisible(false);
        setTicId(null);
    };

    return (
        <Fragment>
            <Card hoverable
                  onClick={() => openTicModal()}>
                <Icon type="plus"/>
                <p>ADD TIC</p>
            </Card>
            <br/>
            {
                globalUser.roleCode === "super_admin" &&
                <ListFilter placeholder="Select Group"
                            onSelect={groupId => setGroupId(groupId)}
                            defaultValue={globalGroupId || undefined}
                            filterOptions={globalGroups}
                            filterOptionOrderBy="groupName"
                            filterOptionName="groupName"/>
            }
            <List dataSource={globalTics.filter(globalTic => globalTic.groupId === globalGroupId)}
                  grid={{
                      gutter: 8,
                      xs: 1,
                      md: 2,
                      lg: 4,
                      xl: 6
                  }}
                  renderItem={(tic, index) => (
                      <List.Item key={index}>
                          <Card hoverable
                                actions={[
                                    <Icon type="edit"
                                          onClick={() => openTicModal(tic.id)}/>,
                                    <Icon type="delete"
                                          onClick={() => deleteTic(tic.id)}/>
                                ]}>
                              <Icon type="home"/>
                              <p>
                                  {tic.name}
                              </p>
                          </Card>
                      </List.Item>
                  )}/>
            {
                isModalVisible &&
                <Tic ticId={ticId}
                     onClose={closeTicModal}/>
            }
        </Fragment>
    )
};
