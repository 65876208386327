import React, {Fragment, useEffect, useGlobal, useState} from "reactn";
import {Button as AntButton, Collapse, DatePicker, Divider, Icon, List, Modal, Select, Tag} from "antd";
import moment from "moment";
import {firestore} from "../../firebase";
import {querySnapshotToArray} from "../../utils";
import {
    Button,
    FiltersGrid,
    ListHeader,
    ListRow,
    NoData,
    Note,
    PanelBody,
    PanelHeader,
    SalesIndicator
} from "../../components/complexList";
import {Countries} from "../../utils/countries";
import {get, uniqBy} from "lodash";
import {useHistory} from "react-router";
import {SaleDetails} from "./SaleDetails";

export const ClientForms = () => {
    const [globalUser] = useGlobal("user");
    const [globalTics] = useGlobal("tics");
    const [globalTicId, setGlobalTicId] = useGlobal("ticId");
    const [clientFormsEntryDate, setClientFormsEntryDate] = useState(moment());
    const [ticId, setTicId] = useState(globalUser.ticId || undefined);
    const [clientForms, setClientForms] = useState([]);
    const [user, setUser] = useState(null);

    const history = useHistory();

    useEffect(() => {
        if (ticId) setGlobalTicId(ticId);
    }, [ticId, setGlobalTicId]);

    useEffect(() => {
        const fetchClientForms = async (entryDate, ticId) => {
            const startOfDay = entryDate.startOf("day").toDate();
            const endOfDay = entryDate.endOf("day").toDate();

            const clientFormsQuerySnapshot = await firestore
                .collection("clientForms")
                .where("tic.id", "==", ticId)
                .where("entryDate", ">=", startOfDay)
                .where("entryDate", "<=", endOfDay)
                .where("status", "==", "confirmed")
                .orderBy("entryDate", "desc")
                .get();

            return setClientForms(querySnapshotToArray(clientFormsQuerySnapshot));
        };

        if (clientFormsEntryDate && globalTicId) fetchClientForms(clientFormsEntryDate, globalTicId);
    }, [clientFormsEntryDate, globalTicId]);

    const renderSalesInfo = (sales = [], note) => {
        const totalCashSoles = sales
            .reduce(
                (sale, prevSale) =>
                    get(sale, "paid.total", 0) + get(prevSale, "paid.total", 0),
                0
            )
            .toFixed(2);

        const totalCashDollars = sales
            .reduce(
                (sale, prevSale) =>
                    get(sale, "paidDollars.total", 0) + get(prevSale, "paidDollars.total", 0),
                0
            )
            .toFixed(2);

        const totalCardDollars = sales
            .reduce(
                (sale, prevSale) =>
                    get(sale, "card.total", 0) + get(prevSale, "card.total", 0),
                0
            )
            .toFixed(2);

        return (
            <Fragment>
                {
                    sales.length > 0 &&
                    <Fragment>
                        <Divider type="vertical"/>
                        <p>Cash: ${totalCashDollars} - S/{totalCashSoles}</p>
                        <Divider type="vertical"/>
                        <p>Card: ${totalCardDollars}</p>
                    </Fragment>
                }
                {
                    note &&
                    <Fragment>
                        <Divider type="vertical"/>
                        <Note>{note}</Note>
                    </Fragment>
                }
                <SalesIndicator hasSales={sales.length}>
                    {sales.length ? "Sales" : "No Sales"}
                </SalesIndicator>
            </Fragment>
        )
    };

    const deleteClientForm = clientFormId => Modal
        .confirm({
            title: "Are you sure delete?",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk: async () => {
                await firestore
                    .collection("clientForms")
                    .doc(clientFormId)
                    .delete();

                setClientFormsEntryDate(moment());
            }
        });

    return (
        <Fragment>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
                <Button type="primary"
                        disabled={!globalTicId}
                        onClick={() => history.push(`/tics/${globalTicId}/client-forms/new`)}>
                    <Icon type="plus"/>
                    <p>NEW FORMS</p>
                </Button>
                <AntButton type="primary"
                           onClick={() => history.push(`/tics/${globalTicId}/client-forms/${clientFormsEntryDate.format("YYYY-MM-DD")}/print`)}
                           disabled={!clientForms.length}
                           icon="printer">
                    PRINT
                </AntButton>
            </div>
            <FiltersGrid>
                <DatePicker value={clientFormsEntryDate}
                            onChange={setClientFormsEntryDate}
                            format="DD/MM/YYYY"/>
                {
                    globalUser.roleCode !== "seller" &&
                    <Select value={globalTicId || undefined}
                            placeholder="Select tic"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            onSelect={setTicId}
                            showSearch>
                        {
                            globalTics
                                .filter(globalTic => globalUser.roleCode === "group_admin" ? globalTic.groupId === globalUser.groupId : true)
                                .map(globalTic =>
                                    <Select.Option key={globalTic.id}
                                                   value={globalTic.id}>
                                        {globalTic.name}
                                    </Select.Option>
                                )
                        }
                    </Select>
                }
                <Select value={user || null}
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        onSelect={setUser}
                        showSearch>
                    <Select.Option key="all"
                                   value={null}>
                        All users
                    </Select.Option>
                    {
                        uniqBy(clientForms, clientForm => get(clientForm, "user.email", null))
                            .map(clientForm =>
                                <Select.Option key={clientForm.id}
                                               value={get(clientForm, "user.email", null)}>
                                    {get(clientForm, "user.email", null)}
                                </Select.Option>
                            )
                    }
                </Select>
            </FiltersGrid>
            {
                clientForms.length
                    ? (
                        <Collapse accordion>
                            {
                                clientForms
                                    .filter(clientForm => user ? get(clientForm, "user.email", null) === user : true)
                                    .map(clientForm =>
                                        <Collapse.Panel key={clientForm.id}
                                                        header={
                                                            <PanelHeader>
                                                                <p>{moment(clientForm.entryDate.toDate()).format("h:mm a")}</p>
                                                                <Divider type="vertical"/>
                                                                <p>Sent by {get(clientForm, "user.email", null)}</p>
                                                                <Divider type="vertical"/>
                                                                <p>{clientForm.numberOfPeople} from {Countries.find(country => country.code === clientForm.country).countryName}</p>
                                                                <Divider type="vertical"/>
                                                                {
                                                                    clientForm.typesInformation.map(typeOfInformation =>
                                                                        <Tag key={typeOfInformation.id}>
                                                                            {typeOfInformation.name}
                                                                        </Tag>
                                                                    )
                                                                }
                                                                {
                                                                    renderSalesInfo(clientForm.sales, clientForm.note)
                                                                }
                                                            </PanelHeader>
                                                        }>
                                            <PanelBody>
                                                <div>
                                                    <h3>Nationality</h3>
                                                    <p>{Countries.find(country => country.code === clientForm.country).countryName}</p>
                                                </div>
                                                <div>
                                                    <h3>Number of people</h3>
                                                    <p>{clientForm.numberOfPeople}</p>
                                                </div>
                                                <div>
                                                    <h3>Type of information</h3>
                                                    {
                                                        clientForm.typesInformation.map(typeOfInformation =>
                                                            <p key={typeOfInformation.id}>
                                                                <Tag>
                                                                    {typeOfInformation.name}
                                                                </Tag>
                                                            </p>
                                                        )
                                                    }
                                                </div>
                                                <div>
                                                    <h3>Note</h3>
                                                    <p style={{maxWidth: "350px", overflow: "hidden"}}>
                                                        {clientForm.note}
                                                    </p>
                                                </div>
                                                <div>
                                                    <h3>Action</h3>
                                                    <Fragment>
                                                        <Icon type="delete"
                                                              onClick={() => deleteClientForm(clientForm.id)}
                                                              style={{fontSize: "20px", color: "red"}}/>
                                                        <Divider type="vertical"/>
                                                        <Icon type="edit"
                                                              onClick={() => history.push(`/tics/${globalTicId}/client-forms/${clientForm.id}`)}
                                                              style={{fontSize: "20px", color: "#1890ff"}}/>
                                                    </Fragment>
                                                </div>
                                            </PanelBody>
                                            {
                                                clientForm.sales && clientForm.sales.length > 0 &&
                                                <List bordered
                                                      header={
                                                          <ListHeader>
                                                              <p>FULL NAME</p>
                                                              <p>NUMBER OF PEOPLE</p>
                                                              <p>TOTAL PRICE</p>
                                                              <p>DISCOUNT</p>
                                                              <p>PAY DOLLARS</p>
                                                              <p>PAY SOLES</p>
                                                              <p>PAY CARD</p>
                                                              <p>ACTION</p>
                                                          </ListHeader>
                                                      }>
                                                    {
                                                        clientForm.sales.map((clientFormSale, index) =>
                                                            <List.Item key={index}>
                                                                <ListRow>
                                                                    <p>{clientFormSale.firstName} {clientFormSale.lastName}</p>
                                                                    <p>{clientFormSale.numberOfPerson}</p>
                                                                    <p>{clientFormSale.totalPrice.currency} {get(clientFormSale, "totalPrice.total", 0).toFixed(2)}</p>
                                                                    <p>{clientFormSale.discount ? `${clientFormSale.discount.currency} ${get(clientFormSale, "discount.total", 0).toFixed(2)}` : null}</p>
                                                                    <p>$ {get(clientFormSale, "paidDollars.total", 0).toFixed(2)}</p>
                                                                    <p>S/ {get(clientFormSale, "paid.total", 0).toFixed(2)}</p>
                                                                    <p>$ {get(clientFormSale, "card.total", 0).toFixed(2)}</p>
                                                                    <p>
                                                                        <SaleDetails sale={clientFormSale}/>
                                                                    </p>
                                                                </ListRow>
                                                            </List.Item>
                                                        )
                                                    }
                                                </List>
                                            }
                                        </Collapse.Panel>
                                    )
                            }
                        </Collapse>
                    )
                    : (
                        <NoData>
                            No data
                        </NoData>
                    )

            }
        </Fragment>
    )
};
