import React, {Fragment, useEffect, useGlobal, useState} from "reactn";
import {Divider, Icon, Modal} from "antd";
import {Card, List, ListFilter} from "../../components/list";
import {userRoles} from "../../utils/dataList";
import {User} from "./User";
import UrlAssembler from "url-assembler";
import {serverUrl} from "../../firebase/config";

export const Users = () => {
    const [globalUser] = useGlobal("user");
    const [globalUsers] = useGlobal("users");
    const [globalGroups] = useGlobal("groups");
    const [globalTics] = useGlobal("tics");
    const [globalRoleCode, setGlobalRoleCode] = useGlobal("roleCode");
    const [globalGroupId, setGlobalGroupId] = useGlobal("groupId");
    const [globalTicId, setGlobalTicId] = useGlobal("ticId");
    const [roleCode, setRoleCode] = useState(null);
    const [groupId, setGroupId] = useState(globalUser.groupId);
    const [ticId, setTicId] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [userId, setUserId] = useState(null);

    const optionRoles = {
        "super_admin": [
            userRoles.super_admin,
            userRoles.group_admin,
            userRoles.seller,
            userRoles.free_walking_tour,
        ],
        "group_admin": [
            userRoles.seller,
            userRoles.free_walking_tour,
        ],
        "seller": [],
        "free_walking_tour": []
    };

    useEffect(() => {
        if (roleCode) setGlobalRoleCode(roleCode);
    }, [roleCode, setGlobalRoleCode]);

    useEffect(() => {
        if (groupId) setGlobalGroupId(groupId);
    }, [groupId, setGlobalGroupId]);

    useEffect(() => {
        if (ticId) setGlobalTicId(ticId);
    }, [ticId, setGlobalTicId]);

    const deleteUserApi = async userId => {
        const response = await fetch(urlApiSetUser(userId), {
            method: "DELETE",
            headers: {
                "content-Type": "application/json",
                "Accept": "application/json",
            }
        });

        if (!response.ok) {
            const error = await response.text();
            throw Error(error);
        }
    };

    const urlApiSetUser = userId =>
        new UrlAssembler(serverUrl)
            .template("/users/:userId")
            .param({userId})
            .toString();

    const deleteUser = userId => Modal
        .confirm({
            title: "Are you sure delete?",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk: async () => await deleteUserApi(userId)
        });

    const openUserModal = userId => {
        setIsModalVisible(true);
        setUserId(userId);
    };

    const closeUserModal = () => {
        setIsModalVisible(false);
        setUserId(null);
    };

    return (
        <Fragment>
            <Card hoverable
                  onClick={() => openUserModal()}>
                <Icon type="plus"/>
                <p>ADD USER</p>
            </Card>
            <ListFilter placeholder="Select Role"
                        onSelect={roleCode => setRoleCode(roleCode)}
                        defaultValue={globalRoleCode || undefined}
                        filterOptions={optionRoles[globalUser.roleCode]}
                        filterOptionOrderBy="name"
                        filterOptionValue="code"
                        filterOptionName="name"/>
            {
                globalRoleCode && globalRoleCode !== "super_admin" &&
                <Fragment>
                    {
                        globalUser.roleCode === "super_admin" &&
                        <ListFilter placeholder="Select Group"
                                    onSelect={groupId => setGroupId(groupId)}
                                    width="48%"
                                    defaultValue={globalGroupId || undefined}
                                    filterOptions={globalGroups}
                                    filterOptionOrderBy="groupName"
                                    filterOptionName="groupName"/>
                    }
                    {
                        globalRoleCode === "seller" &&
                        <Fragment>
                            {
                                globalUser.roleCode === "super_admin" &&
                                <Divider type="vertical"
                                         style={{margin: "1.9%"}}/>
                            }
                            <ListFilter placeholder="Select Tic"
                                        onSelect={ticId => setTicId(ticId)}
                                        width="48%"
                                        defaultValue={globalTicId || undefined}
                                        filterOptions={globalTics}
                                        filterOptionOrderBy="name"
                                        filterOptionName="name"/>
                        </Fragment>
                    }
                </Fragment>
            }
            <List dataSource={
                globalUsers
                    .filter(globalUser => globalUser.roleCode === globalRoleCode)
                    .filter(globalUser => globalUser.roleCode === "super_admin" ? true : globalUser.groupId === globalGroupId)
                    .filter(globalUser => globalUser.roleCode === "seller" ? globalUser.ticId === globalTicId : true)
            }
                  grid={{
                      gutter: 8,
                      xs: 1,
                      md: 2,
                      lg: 4,
                      xl: 6
                  }}
                  renderItem={(user, index) => (
                      <List.Item key={index}>
                          <Card hoverable
                                actions={[
                                    <Icon type="edit"
                                          onClick={() => openUserModal(user.id)}/>,
                                    <Icon type="delete"
                                          onClick={() => deleteUser(user.id)}/>
                                ]}>
                              <Icon type="home"/>
                              <p>
                                  {user.email}
                              </p>
                          </Card>
                      </List.Item>
                  )}/>
            {
                isModalVisible &&
                <User userId={userId}
                      optionRoles={optionRoles}
                      onClose={closeUserModal}/>
            }
        </Fragment>
    )
};
