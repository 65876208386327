import React, {useGlobal, useState} from "reactn";
import {Button, Input, Modal, Select} from "antd";
import {Controller, useForm} from "react-hook-form";
import {number, object, string} from "yup";
import styled from "styled-components";
import {firestore} from "../../firebase";
import {useEffect} from "react";
import {get} from "lodash";

export const Extra = props => {
    const [globalCompanies] = useGlobal("companies");
    const [isLoadingSetExtra, setIsLoadingSetExtra] = useState(false);
    const [extra, setExtra] = useState(null);

    const validationSchema = object().shape({
        name: string().required(),
        companyId: string().required(),
        price: number().positive().required()
    });

    const {control, handleSubmit, errors} = useForm({
        reValidateMode: "onSubmit",
        validationSchema
    });

    useEffect(() => {
        const fetchExtra = async extraId => {
            const storedExtraDocumentSnapshot = await firestore
                .collection("extras")
                .doc(extraId)
                .get();

            const storedExtra = storedExtraDocumentSnapshot.data();
            storedExtra.id = storedExtraDocumentSnapshot.id;

            return setExtra(storedExtra);
        };

        const initialize = async () => {
            if (props.extraId) return fetchExtra(props.extraId);

            const extraDocumentReference = firestore
                .collection("extras")
                .doc();

            return setExtra({id: extraDocumentReference.id});
        };

        initialize();
    }, [props.extraId]);

    const onSubmit = async data => {
        const mappedExtra = await mapExtra(data);
        setIsLoadingSetExtra(true);

        await firestore
            .collection("extras")
            .doc(extra.id)
            .set(mappedExtra, {merge: true});

        return props.onClose();
    };

    const mapExtra = async data => {
        const companyDocumentSnapshot = await firestore
            .collection("companies")
            .doc(data.companyId)
            .get();

        const company = companyDocumentSnapshot.data();
        company.id = companyDocumentSnapshot.id;

        return {
            company,
            currency: "$",
            name: data.name,
            price: data.price,
            createAt: props.extraId ? extra.createAt : new Date(),
            updateAt: props.extraId ? new Date() : null
        }
    };

    return (
        <Modal visible
               onCancel={isLoadingSetExtra ? null : props.onClose}
               footer={null}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Label required>Enter the Extra name:</Label>
                <Controller key={`name-${get(extra, "id", null)}`}
                            control={control}
                            defaultValue={get(extra, "name", undefined)}
                            name="name"
                            as={
                                <Input/>
                            }/>
                {errors.name && <Error>{errors.name.message}</Error>}
                <Label required>Select the company:</Label>
                <Controller key={`companyId-${get(extra, "id", null)}`}
                            control={control}
                            defaultValue={get(extra, "company.id", undefined)}
                            name="companyId"
                            as={
                                <Select>
                                    {
                                        globalCompanies.map(globalCompany =>
                                            <Select.Option key={globalCompany.id}
                                                           value={globalCompany.id}>
                                                {globalCompany.name}
                                            </Select.Option>)
                                    }
                                </Select>
                            }/>
                {errors.companyId && <Error>{errors.companyId.message}</Error>}
                <Label required>Price:</Label>
                <Controller key={`price-${get(extra, "id", null)}`}
                            control={control}
                            defaultValue={get(extra, "price", undefined)}
                            name="price"
                            as={
                                <Input type="number"
                                       addonAfter={<span>$</span>}/>
                            }/>
                {errors.price && <Error>{errors.price.message}</Error>}
                <Footer>
                    <Button key="cancel"
                            disabled={isLoadingSetExtra}
                            style={{marginRight: "0.5rem"}}
                            onClick={props.onClose}>
                        CANCEL
                    </Button>
                    <Button key="submit"
                            type="primary"
                            loading={isLoadingSetExtra}
                            htmlType="submit">
                        SAVE
                    </Button>
                </Footer>
            </form>
        </Modal>
    )
};

const Label = styled.label`
  margin: 1rem 0;
  display: block;
  ${props => props.required && `
    :before {
        display: inline-block;
        margin-right: 4px;
        color: #f5222d;
        font-size: 14px;
        font-family: SimSun,sans-serif;
        line-height: 1;
        content: "*";
    }
  `}
`;

const Error = styled.p`
  color: #f5222d;
  margin-bottom: 0;
`;

const Footer = styled.div`
  text-align: right;
  margin-top: 1.5rem;
`;
