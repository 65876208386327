import React, {useGlobal, useState} from "reactn";
import {Button, Input, Modal, Select} from "antd";
import {Controller, useForm} from "react-hook-form";
import {object, string} from "yup";
import styled from "styled-components";
import {firestore} from "../../firebase";
import {Fragment, useEffect} from "react";
import {get} from "lodash";

export const Tic = props => {
    const [globalUser] = useGlobal("user");
    const [globalGroups] = useGlobal("groups");
    const [globalGroupId] = useGlobal("groupId")
    const [isLoadingSetTic, setIsLoadingSetTic] = useState(false);
    const [tic, setTic] = useState(null);

    const validationSchema = object().shape({
        name: string().required(),
        groupId: globalUser.roleCode === "super_admin" && string().required()
    });

    const {control, handleSubmit, errors} = useForm({
        reValidateMode: "onSubmit",
        validationSchema
    });

    useEffect(() => {
        const fetchTic = async ticId => {
            const storedTicDocumentSnapshot = await firestore
                .collection("tics")
                .doc(ticId)
                .get();

            const storedTic = storedTicDocumentSnapshot.data();
            storedTic.id = storedTicDocumentSnapshot.id;

            return setTic(storedTic);
        };

        const initialize = async () => {
            if (props.ticId) return fetchTic(props.ticId);

            const ticDocumentReference = firestore
                .collection("tics")
                .doc();

            return setTic({id: ticDocumentReference.id});
        };

        initialize();
    }, [props.ticId]);

    const onSubmit = async data => {
        const mappedTic = await mapTic(data);
        setIsLoadingSetTic(true);

        await firestore
            .collection("tics")
            .doc(tic.id)
            .set(mappedTic, {merge: true});

        return props.onClose();
    };

    const mapTic = async data => ({
        groupId: data.groupId || globalUser.groupId,
        name: data.name,
        createAt: props.ticId ? tic.createAt : new Date(),
        updateAt: props.ticId ? new Date() : null
    });

    return (
        <Modal visible
               onCancel={isLoadingSetTic ? null : props.onClose}
               footer={null}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Label required>Enter a name:</Label>
                <Controller key={`name-${get(tic, "id", null)}`}
                            control={control}
                            defaultValue={get(tic, "name", undefined)}
                            name="name"
                            as={
                                <Input/>
                            }/>
                {errors.name && <Error>{errors.name.message}</Error>}
                {
                    globalUser.roleCode === "super_admin" &&
                    <Fragment>
                        <Label required>Select group:</Label>
                        <Controller key={`groupId-${get(tic, "id", null)}`}
                                    control={control}
                                    defaultValue={get(tic, "groupId", globalGroupId)}
                                    name="groupId"
                                    as={
                                        <Select>
                                            {
                                                globalGroups.map(globalGroup =>
                                                    <Select.Option key={globalGroup.id}
                                                                   value={globalGroup.id}>
                                                        {globalGroup.groupName}
                                                    </Select.Option>)
                                            }
                                        </Select>
                                    }/>
                        {errors.groupId && <Error>{errors.groupId.message}</Error>}
                    </Fragment>
                }
                <Footer>
                    <Button key="cancel"
                            disabled={isLoadingSetTic}
                            style={{marginRight: "0.5rem"}}
                            onClick={props.onClose}>
                        CANCEL
                    </Button>
                    <Button key="submit"
                            type="primary"
                            loading={isLoadingSetTic}
                            htmlType="submit">
                        SAVE
                    </Button>
                </Footer>
            </form>
        </Modal>
    )
};

const Label = styled.label`
  margin: 1rem 0;
  display: block;
  ${props => props.required && `
    :before {
        display: inline-block;
        margin-right: 4px;
        color: #f5222d;
        font-size: 14px;
        font-family: SimSun,sans-serif;
        line-height: 1;
        content: "*";
    }
  `}
`;

const Error = styled.p`
  color: #f5222d;
  margin-bottom: 0;
`;

const Footer = styled.div`
  text-align: right;
  margin-top: 1.5rem;
`;
