export const userRoles = {
    "super_admin": {
        code: "super_admin",
        name: "Super admin",
        redirect: "/client-forms"
    },
    "group_admin": {
        code: "group_admin",
        name: "Group admin",
        redirect: "/client-forms"
    },
    "seller": {
        code: "seller",
        name: "Seller",
        redirect: "/client-forms"
    },
    "free_walking_tour": {
        code: "free_walking_tour",
        name: "Free walking tour",
        redirect: "/free-walking-tour-forms-standalone"
    }
};

export const menu = {
    "clientForms": {
        id: "clientForms",
        route: "/client-forms",
        icon: "form",
        title: "Client forms"
    },
    "freeWalkingTours": {
        id: "freeWalkingTours",
        route: "/free-walking-tour-forms",
        icon: "form",
        title: "Free walking tours"
    },
    "freeWalkingToursStandalone": {
        id: "freeWalkingToursStandalone",
        route: "/free-walking-tour-forms-standalone",
        icon: "form",
        title: "Free walking tours"
    },
    "refunds": {
        id: "refunds",
        route: "/refunds",
        icon: "frown",
        title: "Refunds"
    },
    "users": {
        id: "users",
        route: "/users",
        icon: "user",
        title: "Users"
    },
    "groups": {
        id: "groups",
        route: "/groups",
        icon: "cluster",
        title: "Groups"
    },
    "tics": {
        id: "tics",
        route: "/tics",
        icon: "home",
        title: "Tics"
    },
    "hostels": {
        id: "hostels",
        route: "/hostels",
        icon: "bank",
        title: "Hostels"
    },
    "places": {
        id: "places",
        route: "/places",
        icon: "environment",
        title: "Places"
    },
    "informationTypes": {
        id: "informationTypes",
        route: "/information-types",
        icon: "file-text",
        title: "Information Types"
    },
    "companies": {
        id: "companies",
        route: "/companies",
        icon: "file-text",
        title: "Companies"
    },
    "tours": {
        id: "tours",
        route: "/tours",
        icon: "branches",
        title: "Tours"
    },
    "extras": {
        id: "extras",
        route: "/extras",
        icon: "pull-request",
        title: "Extras"
    },
    "hows": {
        id: "hows",
        route: "/hows",
        icon: "question",
        title: "Hows"
    },
};