import React, {useGlobal, useState} from "reactn";
import {Icon, Layout as AntLayout, Menu, Tooltip} from "antd";
import {Link} from "react-router-dom"
import {auth, version} from "../firebase";
import {menu, userRoles} from "../utils/dataList";
import titleize from "titleize";
import {get} from "lodash"
import styled from "styled-components";

const {Header: AntHeader, Content: AntContent, Sider} = AntLayout;

const menuByRole = {
    "super_admin": [
        menu.clientForms,
        menu.freeWalkingTours,
        menu.refunds,
        menu.users,
        menu.groups,
        menu.tics,
        menu.hows,
        menu.hostels,
        menu.places,
        menu.informationTypes,
        menu.companies,
        menu.tours,
        menu.extras
    ],
    "group_admin": [
        menu.clientForms,
        menu.freeWalkingTours,
        menu.refunds,
        menu.users,
        menu.tics,
        menu.hows,
        menu.hostels,
        menu.places,
        menu.informationTypes
    ],
    "seller": [
        menu.clientForms,
        menu.freeWalkingTours,
    ],
    "free_walking_tour": []
};

export const BaseLayout = props => {
    const [globalUser] = useGlobal("user");
    const [globalTics] = useGlobal("tics");
    const [globalGroups] = useGlobal("groups");
    const [collapsed, setCollapsed] = useState(globalUser.roleCode === "free_walking_tour");

    const toggle = () => setCollapsed(prevCollapsedState => !prevCollapsedState);

    const headerTitle = () => {
        let place;

        switch (globalUser.roleCode) {
            case "super_admin":
                place = get(globalTics.find(tic => tic.id === globalUser.ticId), "name", "TIC");
                break;
            case "group_admin":
                place = get(globalGroups.find(group => group.id === globalUser.groupId), "groupName", "");
                break;
            case "seller":
                place = get(globalTics.find(tic => tic.id === globalUser.ticId), "name", "");
                break;
            case "free_walking_tour":
                place = get(globalGroups.find(group => group.id === globalUser.groupId), "groupName", "");
                break;
            default :
                place = "";
        }

        return `${titleize(place)} | ${globalUser.email} | ${userRoles[globalUser.roleCode].name}`;
    }

    return (
        <Layout>
            <Sider trigger={null}
                   collapsible
                   collapsedWidth={0}
                   collapsed={collapsed}>
                <Version>Version {version}</Version>
                <Menu theme="dark"
                      mode="inline"
                      defaultSelectedKeys={[window.location.pathname]}>
                    {
                        menuByRole[globalUser.roleCode].map(menu =>
                            <Menu.Item key={menu.route}>
                                <Link to={menu.route}
                                      id={menu.id}>
                                    <Icon type={menu.icon}/>
                                    <span>{menu.title}</span>
                                </Link>
                            </Menu.Item>
                        )
                    }
                    <Menu.Item key="logout"
                               onClick={async () => await auth.signOut()}>
                        <Icon type="logout"/>
                        <span>Logout</span>
                    </Menu.Item>
                </Menu>
            </Sider>
            <Layout>
                <Header isFwt={globalUser.roleCode === "free_walking_tour"}>
                    {
                        globalUser.roleCode !== "free_walking_tour" &&
                        <Trigger type={collapsed ? "menu-unfold" : "menu-fold"}
                                 onClick={toggle}/>
                    }
                    <h1>
                        {headerTitle()}
                    </h1>
                    {
                        globalUser.roleCode === "free_walking_tour" &&
                        <Tooltip title="Logout">
                            <Icon onClick={async () => await auth.signOut()}
                                  style={{fontSize: "20px"}}
                                  type="logout"/>
                        </Tooltip>
                    }
                </Header>
                <Content>
                    {props.children}
                </Content>
            </Layout>
        </Layout>
    );
};

const Version = styled.div`
  text-align: center;
  margin: 1rem;
  font-size: 18px;
  line-height: 32px;
  background-color: #a9a9a9;
`;

const Layout = styled(AntLayout)`
  height: 100vh;
`;

const Header = styled(AntHeader)`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  padding: 0;
  
  ${props => props.isFwt && `
    justify-content: space-between;
    padding: 0 1.5rem;
  `}
  
  h1 {
    margin: 0;
    font-size: 20px;
    font-weight: bold;
  }
`;

const Trigger = styled(Icon)`
  padding: 0 1.5rem;
  font-size: 18px;
`;

const Content = styled(AntContent)`
  margin: 1.5rem 1rem;
  padding: 1.5rem;
  background-color: white;
  min-height: auto;
`;
