import React, {useGlobal} from "reactn";
import {Redirect, Route} from "react-router-dom";
import {get} from "lodash";

export const SellerPrivateRoute = props => {
    const [globalUser] = useGlobal("user");

    return (
        <Route exact
               path={props.path}
               render={() => get(globalUser, "roleCode", "") === "free_walking_tour"
                   ? <Redirect to={`/groups/${globalUser.groupId}/free-walking-tour-forms/new`}/>
                   : globalUser ? props.children : <Redirect to="/login"/>}
        />
    );
}
