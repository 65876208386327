import React, {useEffect, useGlobal, useMemo, useState} from "reactn";
import styled from "styled-components";
import {Button, DatePicker as AntDatePicker, Input, Radio, Select} from "antd";
import {Controller, useForm} from "react-hook-form";
import {array, number, object, string} from "yup";
import {assign, get} from "lodash";
import {useHistory, useParams} from "react-router";
import {Countries} from "../../../../../utils/countries";
import moment from "moment";
import {firestore} from "../../../../../firebase";

export const FreeWalkingTourForm = () => {
    const {groupId, freeWalkingTourFormId} = useParams();
    const history = useHistory();

    const validationSchema = object().shape({
        placeId: string().required().nullable(),
        startingPoint: string().required().nullable(),
        tripCusco: string().required().nullable(),
        entryDate: string().required(),
        firstName: string().required(),
        lastName: string().required(),
        language: string().required(),
        age: number().integer().positive().required(),
        email: string().required(),
        country: string().required(),
        hows: array().required(),
        hostel: string()
    });

    const {control, errors, handleSubmit} = useForm({
        validationSchema,
        reValidateMode: "onSubmit"
    });

    const [globalHostels] = useGlobal("hostels");
    const [globalHows] = useGlobal("hows");
    const [globalPlaces] = useGlobal("places");
    const [hostels, setHostels] = useState([]);
    const [hows, setHows] = useState([]);
    const [freeWalkingTourForm, setFreeWalkingTourForm] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useMemo(() => {
        const fetchFreeWalkingTourForm = async () => {
            if (freeWalkingTourFormId === "new") {
                const freeWalkingTourFormRef = firestore.collection("freeWalkingTourForms").doc();

                return setFreeWalkingTourForm({id: freeWalkingTourFormRef.id, createAt: new Date()});
            }
            const freeWalkingTourFormDocumentSnapshot = await firestore.collection("freeWalkingTourForms").doc(freeWalkingTourFormId).get();

            if (freeWalkingTourFormDocumentSnapshot.exists) return setFreeWalkingTourForm({
                ...freeWalkingTourFormDocumentSnapshot.data(),
                id: freeWalkingTourFormDocumentSnapshot.id
            });

            return history.goBack();
        };

        fetchFreeWalkingTourForm();
    }, [freeWalkingTourFormId, history]);

    useEffect(() => {
        const filteredHostels = globalHostels
            .filter(globalHostel => globalHostel.groupId === groupId);

        setHostels(filteredHostels);
    }, [groupId, globalHostels]);

    useEffect(() => {
        const filteredHows = globalHows
            .filter(globalHow => globalHow.groupId === groupId);

        setHows(filteredHows);
    }, [groupId, globalHows]);

    const onSubmit = async data => {
        setIsSubmitting(true);

        await firestore
            .collection("freeWalkingTourForms")
            .doc(freeWalkingTourForm.id)
            .set(mappedFreeWalkingTourForm(data), {merge: true});

        return history.goBack();
    };

    const mappedFreeWalkingTourForm = data => {
        const hostel = hostels.find(hostel => hostel.id === data.hostel);
        const mappedHows = data.hows.map(dataHow => hows.find(how => how.id === dataHow));

        return assign({}, freeWalkingTourForm, data, {
            updateAt: new Date(),
            groupId,
            hostel: hostel || null,
            entryDate: moment(data.entryDate).format("YYYY-MM-DD"),
            hows: mappedHows,
            status: "confirmed"
        });
    };

    const viewPlaces = globalPlaces.filter(place => place.groupId === groupId).map(place => ({
        label: place.name,
        value: place.id
    }))

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Label required>
                Place / Lugar
            </Label>
            <Controller key={`place-${get(freeWalkingTourForm, "id", null)}`}
                        name="placeId"
                        defaultValue={get(freeWalkingTourForm, "placeId", null)}
                        onChange={([selected]) => selected.target.value}
                        control={control}
                        as={
                            <Radio.Group options={viewPlaces}/>
                        }/>
            {errors.place && <Error>{errors.place.message}</Error>}
            <Label required>
                Starting point / Punto de salida
            </Label>
            <Controller key={`startingPoint-${get(freeWalkingTourForm, "id", null)}`}
                        name="startingPoint"
                        defaultValue={get(freeWalkingTourForm, "startingPoint", null)}
                        onChange={([selected]) => selected.target.value}
                        control={control}
                        as={
                            <Radio.Group options={[
                                {
                                    label: "TIC Larco",
                                    value: "tic_larco"
                                },
                                {
                                    label: "TIC Kennedy",
                                    value: "tic_kennedy"
                                },
                                {
                                    label: "Pariwana",
                                    value: "pariwana"
                                },
                                {
                                    label: "1900 Backpackers",
                                    value: "1900_backpackers"
                                }
                            ]}/>
                        }/>
            {errors.startingPoint && <Error>{errors.startingPoint.message}</Error>}
            <Label required>
                How was your trip to Cusco? / ¿Como fue tu viaje a Cusco?
            </Label>
            <Controller key={`tripCusco-${get(freeWalkingTourForm, "id", null)}`}
                        name="tripCusco"
                        control={control}
                        defaultValue={get(freeWalkingTourForm, "tripCusco", null)}
                        onChange={([selected]) => selected.target.value}
                        as={
                            <Radio.Group options={[
                                {
                                    label: "Great",
                                    value: "great"
                                },
                                {
                                    label: "Average",
                                    value: "average"
                                },
                                {
                                    label: "Poor",
                                    value: "poor"
                                },
                                {
                                    label: "Not arrived yet",
                                    value: "not_arrive_yet"
                                }
                            ]}/>
                        }/>
            {errors.tripCusco && <Error>{errors.tripCusco.message}</Error>}
            <Label required>
                Date / Fecha
            </Label>
            <Controller key={`entryDate-${get(freeWalkingTourForm, "id", null)}`}
                        name="entryDate"
                        defaultValue={get(freeWalkingTourForm, "entryDate", null) ? moment(freeWalkingTourForm.entryDate) : moment()}
                        control={control}
                        as={
                            <DatePicker format="DD/MM/YYYY"/>
                        }/>
            {errors.entryDate && <Error>{errors.entryDate.message}</Error>}
            <Label required>
                First name / Nombre
            </Label>
            <Controller key={`firstName-${get(freeWalkingTourForm, "id", null)}`}
                        name="firstName"
                        defaultValue={get(freeWalkingTourForm, "firstName", undefined)}
                        control={control}
                        as={
                            <Input/>
                        }/>
            {errors.firstName && <Error>{errors.firstName.message}</Error>}
            <Label required>
                Last name / Apellidos
            </Label>
            <Controller key={`lastName-${get(freeWalkingTourForm, "id", null)}`}
                        name="lastName"
                        defaultValue={get(freeWalkingTourForm, "lastName", undefined)}
                        control={control}
                        as={
                            <Input/>
                        }/>
            {errors.lastName && <Error>{errors.lastName.message}</Error>}
            <Label required>
                Language / Idioma
            </Label>
            <Controller key={`language-${get(freeWalkingTourForm, "id", null)}`}
                        name="language"
                        defaultValue={get(freeWalkingTourForm, "language", undefined)}
                        control={control}
                        as={
                            <Select showSearch
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                <Select.Option value="spanish">
                                    Español
                                </Select.Option>
                                <Select.Option value="english">
                                    English
                                </Select.Option>
                            </Select>
                        }/>
            {errors.language && <Error>{errors.language.message}</Error>}
            <Label required>
                Age / Edad
            </Label>
            <Controller key={`age-${get(freeWalkingTourForm, "id", null)}`}
                        name="age"
                        defaultValue={get(freeWalkingTourForm, "age", undefined)}
                        control={control}
                        as={
                            <Input type="number"/>
                        }/>
            {errors.age && <Error>{errors.age.message}</Error>}
            <Label required>
                Email / Correo electrónico
            </Label>
            <Controller key={`email-${get(freeWalkingTourForm, "id", null)}`}
                        name="email"
                        defaultValue={get(freeWalkingTourForm, "email", undefined)}
                        control={control}
                        as={
                            <Input/>
                        }/>
            {errors.email && <Error>{errors.email.message}</Error>}
            <Label required>
                Nationality / Nacionalidad
            </Label>
            <Controller key={`country-${get(freeWalkingTourForm, "id", null)}`}
                        name="country"
                        defaultValue={get(freeWalkingTourForm, "country", undefined)}
                        control={control}
                        as={
                            <Select showSearch
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                {
                                    Countries.map(country =>
                                        <Select.Option key={country.code}
                                                       value={country.code}>
                                            {country.countryName}
                                        </Select.Option>
                                    )
                                }
                            </Select>
                        }/>
            {errors.country && <Error>{errors.country.message}</Error>}
            <Label required>
                How did you find us? / ¿Cómo se enteró de nosotros?
            </Label>
            <Controller key={`hows-${get(freeWalkingTourForm, "id", null)}`}
                        name="hows"
                        defaultValue={get(freeWalkingTourForm, "hows", []).map(how => how.id)}
                        control={control}
                        as={
                            <Select showSearch
                                    mode="multiple"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                {
                                    hows.map(how =>
                                        <Select.Option key={how.id}
                                                       value={how.id}>
                                            {how.name}
                                        </Select.Option>
                                    )
                                }
                            </Select>
                        }/>
            {errors.hows && <Error>{errors.hows.message}</Error>}
            <Label>
                Hostel / Hostal
            </Label>
            <Controller key={`hostel-${get(freeWalkingTourForm, "id", null)}`}
                        name="hostel"
                        defaultValue={get(freeWalkingTourForm, "hostel.id", undefined)}
                        control={control}
                        as={
                            <Select showSearch
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                {
                                    hostels.map(hostel =>
                                        <Select.Option key={hostel.id}
                                                       value={hostel.id}>
                                            {hostel.name}
                                        </Select.Option>
                                    )
                                }
                            </Select>
                        }/>
            {errors.hostel && <Error>{errors.hostel.message}</Error>}
            <Button block
                    loading={isSubmitting}
                    htmlType="submit"
                    style={{marginTop: "2rem"}}
                    type="primary">
                SUBMIT
            </Button>
        </form>
    )
};

const Label = styled.label`
  margin: 1rem 0;
  display: block;
  ${props => props.required && `
    :before {
        display: inline-block;
        margin-right: 4px;
        color: #f5222d;
        font-size: 14px;
        font-family: SimSun,sans-serif;
        line-height: 1;
        content: "*";
    }
  `}
`;

const Error = styled.p`
  color: #f5222d;
  margin-bottom: 0;
`;

const DatePicker = styled(AntDatePicker)`
  width: 100%;
`;
