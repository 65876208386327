import styled from "styled-components";

export const Container = styled.section`
  padding-top: 40px;
  text-align: center;
  
  h1 {
    margin-top: 0;
    color: rgba(0,0,0,.85);
    font-weight: 500;
    font-size: 2em;
  }
`;
