import React, {useState} from "reactn";
import {Button, Input, Modal} from "antd";
import {Controller, useForm} from "react-hook-form";
import {object, string} from "yup";
import styled from "styled-components";
import {firestore} from "../../firebase";
import {useEffect} from "react";
import {get} from "lodash";

export const Group = props => {
    const [isLoadingSetGroup, setIsLoadingSetGroup] = useState(false);
    const [group, setGroup] = useState(null);

    const validationSchema = object().shape({
        name: string().required()
    });

    const {control, handleSubmit, errors} = useForm({
        reValidateMode: "onSubmit",
        validationSchema
    });

    useEffect(() => {
        const fetchGroup = async groupId => {
            const storedGroupDocumentSnapshot = await firestore
                .collection("groups")
                .doc(groupId)
                .get();

            const storedGroup = storedGroupDocumentSnapshot.data();
            storedGroup.id = storedGroupDocumentSnapshot.id;

            return setGroup(storedGroup);
        };

        const initialize = async () => {
            if (props.groupId) return fetchGroup(props.groupId);

            const groupDocumentReference = firestore
                .collection("groups")
                .doc();

            return setGroup({id: groupDocumentReference.id});
        };

        initialize();
    }, [props.groupId]);

    const onSubmit = async data => {
        const mappedGroup = await mapGroup(data);
        setIsLoadingSetGroup(true);

        await firestore
            .collection("groups")
            .doc(group.id)
            .set(mappedGroup, {merge: true});

        return props.onClose();
    };

    const mapGroup = async data => {
        return {
            groupName: data.name,
            createAt: props.groupId ? group.createAt : new Date(),
            updateAt: props.groupId ? new Date() : null
        }
    };

    return (
        <Modal visible
               onCancel={isLoadingSetGroup ? null : props.onClose}
               footer={null}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Label required>Enter a name:</Label>
                <Controller key={`name-${get(group, "id", null)}`}
                            control={control}
                            defaultValue={get(group, "groupName", undefined)}
                            name="name"
                            as={
                                <Input/>
                            }/>
                {errors.name && <Error>{errors.name.message}</Error>}
                <Footer>
                    <Button key="cancel"
                            disabled={isLoadingSetGroup}
                            style={{marginRight: "0.5rem"}}
                            onClick={props.onClose}>
                        CANCEL
                    </Button>
                    <Button key="submit"
                            type="primary"
                            loading={isLoadingSetGroup}
                            htmlType="submit">
                        SAVE
                    </Button>
                </Footer>
            </form>
        </Modal>
    )
};

const Label = styled.label`
  margin: 1rem 0;
  display: block;
  ${props => props.required && `
    :before {
        display: inline-block;
        margin-right: 4px;
        color: #f5222d;
        font-size: 14px;
        font-family: SimSun,sans-serif;
        line-height: 1;
        content: "*";
    }
  `}
`;

const Error = styled.p`
  color: #f5222d;
  margin-bottom: 0;
`;

const Footer = styled.div`
  text-align: right;
  margin-top: 1.5rem;
`;
