import React, {Fragment, useEffect, useGlobal, useState} from "reactn";
import {Button, Input, Modal, notification, Radio as AntRadio, Select} from "antd";
import {Controller, useForm} from "react-hook-form";
import {object, string} from "yup";
import styled from "styled-components";
import {firestore} from "../../firebase";
import {get} from "lodash";
import UrlAssembler from "url-assembler";
import {serverUrl} from "../../firebase/config";

export const User = props => {
    const [globalUser] = useGlobal("user");
    const [globalGroups] = useGlobal("groups");
    const [globalTics] = useGlobal("tics");
    const [isLoadingSetUser, setIsLoadingSetUser] = useState(false);
    const [user, setUser] = useState(null);
    const [emailExistsError, setEmailExistsError] = useState(false);
    const [isNewUser, setIsNewUser] = useState(false);

    const validationSchema = object().shape({
        email: string().email().required(),
        password: string().min(6).required(),
        roleCode: string().required(),
        groupId: globalUser.roleCode === "super_admin" && string().when("roleCode", {
            is: roleCode => roleCode !== "super_admin",
            then: string().required()
        }),
        ticId: string().when("roleCode", {
            is: roleCode => roleCode === "seller",
            then: string().required()
        })
    });

    const {control, handleSubmit, errors, watch, setValue} = useForm({
        reValidateMode: "onSubmit",
        validationSchema
    });

    useEffect(() => {
        const fetchUser = async userId => {
            const storedUserDocumentSnapshot = await firestore
                .collection("users")
                .doc(userId)
                .get();

            const storedUser = storedUserDocumentSnapshot.data();
            storedUser.id = storedUserDocumentSnapshot.id;

            return setUser(storedUser);
        };

        const initialize = async () => {
            if (props.userId) return fetchUser(props.userId);

            const userDocumentReference = firestore
                .collection("users")
                .doc();

            setIsNewUser(true);

            return setUser({id: userDocumentReference.id});
        };

        initialize();
    }, [props.userId]);

    useEffect(() => {
        if (user) setValue("roleCode", user.roleCode)
    }, [user]);

    const isEmailExists = async email => {
        const emailQuerySnapshot = await firestore
            .collection("users")
            .where("email", "==", email)
            .get();

        return !emailQuerySnapshot.empty;
    };

    const setUserApi = async user => {
        const response = await fetch(urlApiSetUser(user.id), {
            method: "POST",
            body: JSON.stringify(user),
            headers: {
                "content-Type": "application/json",
                "Accept": "application/json",
            }
        });

        if (!response.ok) {
            const error = await response.text();
            throw Error(error);
        }
    };

    const urlApiSetUser = userId =>
        new UrlAssembler(serverUrl)
            .template("/users/:userId")
            .param({userId})
            .toString();

    const onCreateUserError = () => notification["error"]({
        message: "Create user error"
    });

    const onSubmit = async data => {
        try {
            const mappedUser = await mapUser(data);

            const emailExists = await isEmailExists(mappedUser.email);

            if (emailExists && isNewUser) return setEmailExistsError(true);

            setEmailExistsError(false);
            setIsLoadingSetUser(true);

            await setUserApi(mappedUser);
            return props.onClose();
        } catch (error) {
            onCreateUserError();
            setIsLoadingSetUser(false);
        }
    };

    const mapUser = async data => ({
        id: user.id,
        email: data.email,
        password: data.password,
        roleCode: data.roleCode,
        groupId: data.roleCode === "super_admin" ? null : (get(data, "groupId", null) || globalUser.groupId),
        ticId: get(data, "ticId", null),
        createAt: props.userId ? user.createAt : new Date(),
        updateAt: props.userId ? new Date() : null
    });

    //NO BORRAR
    console.log(watch());

    return (
        <Modal visible
               onCancel={isLoadingSetUser ? null : props.onClose}
               footer={null}>
            {emailExistsError && <Error>Email already exists.</Error>}
            <form onSubmit={handleSubmit(onSubmit)}>
                <Label required>Enter email:</Label>
                <Controller key={`email-${get(user, "id", null)}`}
                            control={control}
                            defaultValue={get(user, "email", undefined)}
                            name="email"
                            as={
                                <Input/>
                            }/>
                {errors.email && <Error>{errors.email.message}</Error>}
                <Label required>Password:</Label>
                <Controller key={`password-${get(user, "id", null)}`}
                            control={control}
                            defaultValue={get(user, "password", undefined)}
                            name="password"
                            as={
                                <Input.Password/>
                            }/>
                {errors.password && <Error>{errors.password.message}</Error>}
                <Controller key={`roleCode-${get(user, "id", null)}`}
                            control={control}
                            defaultValue={get(user, "roleCode", undefined)}
                            name="roleCode"
                            onChange={([selected]) => selected.target.value}
                            as={
                                <RadioGroup options={
                                    props.optionRoles[globalUser.roleCode]
                                        .map(optionRole => ({
                                            label: optionRole.name,
                                            value: optionRole.code
                                        }))
                                }/>
                            }/>
                {errors.roleCode && <Error>{errors.roleCode.message}</Error>}
                {
                    globalUser.roleCode === "super_admin" &&
                    (
                        watch("roleCode") &&
                        (watch("roleCode") !== "super_admin")
                    ) &&
                    <Fragment>
                        <Label required>Select group:</Label>
                        <Controller key={`groupId-${get(user, "id", null)}`}
                                    control={control}
                                    defaultValue={get(user, "groupId", undefined)}
                                    name="groupId"
                                    as={
                                        <Select>
                                            {
                                                globalGroups.map(globalGroup =>
                                                    <Select.Option key={globalGroup.id}
                                                                   value={globalGroup.id}>
                                                        {globalGroup.groupName}
                                                    </Select.Option>)
                                            }
                                        </Select>
                                    }/>
                        {errors.groupId && <Error>{errors.groupId.message}</Error>}
                    </Fragment>
                }
                {
                    watch("roleCode") === "seller" &&
                    <Fragment>
                        <Label required>Select tic:</Label>
                        <Controller key={`ticId-${get(user, "id", null)}`}
                                    control={control}
                                    defaultValue={get(user, "ticId", undefined)}
                                    name="ticId"
                                    as={
                                        <Select>
                                            {
                                                globalTics.map(globalTic =>
                                                    <Select.Option key={globalTic.id}
                                                                   value={globalTic.id}>
                                                        {globalTic.name}
                                                    </Select.Option>)
                                            }
                                        </Select>
                                    }/>
                        {errors.ticId && <Error>{errors.ticId.message}</Error>}
                    </Fragment>
                }
                <Footer>
                    <Button key="cancel"
                            disabled={isLoadingSetUser}
                            style={{marginRight: "0.5rem"}}
                            onClick={props.onClose}>
                        CANCEL
                    </Button>
                    <Button key="submit"
                            type="primary"
                            loading={isLoadingSetUser}
                            htmlType="submit">
                        SAVE
                    </Button>
                </Footer>
            </form>
        </Modal>
    )
};

const Label = styled.label`
  margin: 1rem 0;
  display: block;
  ${props => props.required && `
    :before {
        display: inline-block;
        margin-right: 4px;
        color: #f5222d;
        font-size: 14px;
        font-family: SimSun,sans-serif;
        line-height: 1;
        content: "*";
    }
  `}
`;

const Error = styled.p`
  color: #f5222d;
  margin-bottom: 0;
`;

const Footer = styled.div`
  text-align: right;
  margin-top: 1.5rem;
`;

const RadioGroup = styled(AntRadio.Group)`
  margin-top: 1.5rem;
`;
