import React, {Fragment, useGlobal, useState} from "reactn";
import {Icon, Modal} from "antd";
import {firestore} from "../../firebase";
import {Card, DeleteButton, Description, List} from "../../components/list";
import {Extra} from "./Extra";

export const Extras = () => {
    const [globalExtras] = useGlobal("extras");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [extraId, setExtraId] = useState(null);

    const deleteExtra = extraId => Modal
        .confirm({
            title: "Are you sure delete?",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk: () => {
                firestore
                    .collection("extras")
                    .doc(extraId)
                    .delete();
            }
        });

    const openExtraModal = extraId => {
        setIsModalVisible(true);
        setExtraId(extraId);
    };

    const closeExtraModal = () => {
        setIsModalVisible(false);
        setExtraId(null);
    };

    return (
        <Fragment>
            <Card hoverable
                  onClick={() => openExtraModal()}>
                <Icon type="plus"/>
                <p>ADD EXTRA</p>
            </Card>
            <List itemLayout="horizontal"
                  dataSource={globalExtras}
                  renderItem={(extra, index) => (
                      <List.Item key={index}
                                 actions={[
                                     <DeleteButton onClick={() => deleteExtra(extra.id)}>
                                         <Icon type="delete"/>
                                     </DeleteButton>
                                 ]}>
                          <List.Item.Meta description={
                              <Description onClick={() => openExtraModal(extra.id)}>
                                  {extra.name}
                              </Description>
                          }/>
                          <span>{`${extra.currency} ${extra.price.toFixed(2)}`}</span>
                      </List.Item>
                  )}/>
            {
                isModalVisible &&
                <Extra extraId={extraId}
                       onClose={closeExtraModal}/>
            }
        </Fragment>
    )
};
