import React, {useGlobal} from "reactn";
import {Redirect, Route} from "react-router-dom";
import {get} from "lodash";

export const GroupAdminPrivateRoute = props => {
    const [globalUser] = useGlobal("user");

    return (
        <Route exact
               path={props.path}
               render={() => get(globalUser, "roleCode", "") === "group_admin" || get(globalUser, "roleCode", "") === "super_admin"
                   ? props.children
                   : <Redirect to="/client-forms"/>
               }
        />
    );
}
