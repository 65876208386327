import styled from "styled-components";
import {Button as AntButton} from "antd";

export const Button = styled(AntButton)`
  width: 130px !important;
  height: 110px !important;
  
  p {
    margin: 0 !important;
  }
  
  i {
    font-size: 2em;
  }
`;

export const FiltersGrid = styled.div`
  display: grid;
  grid-template-columns: ${props => props.children.length > 2 ? "1fr 2fr 2fr" : "1fr 4fr"};
  grid-gap: 1rem;
  width: 100%;
  margin: 1rem 0;
`;

export const NoData = styled.div`
  width: 100%;
  padding: 1rem 0;
  border: 1px solid #d9d9d9;
  color: #9b9b9b;
  text-align: center;
`;

export const PanelHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  p {
    margin-bottom: 0;
  }
`;

export const ListHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.children.length}, 1fr);
  align-items: center;
  
  p {
    margin-bottom: 0;
    font-weight: bold;
    text-transform: uppercase;
  }
`;

export const ListRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(${props => props.children.length}, 1fr);
  align-items: center;
`;

export const PanelBody = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(${props => props.children.length}, 1fr);
`;

export const SalesIndicator = styled.p`
  margin-left: auto;
  color: ${props => props.hasSales ? "#43a047" : "#ef5350"};
`;

export const Note = styled.p`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
`;
