import React, {useState} from "reactn";
import {Button, Input, Modal} from "antd";
import {Controller, useForm} from "react-hook-form";
import {object, string} from "yup";
import styled from "styled-components";
import {firestore} from "../../firebase";
import {useEffect} from "react";
import {get} from "lodash";

export const Company = props => {
    const [isLoadingSetCompany, setIsLoadingSetCompany] = useState(false);
    const [company, setCompany] = useState(null);

    const validationSchema = object().shape({
        name: string().required()
    });

    const {control, handleSubmit, errors} = useForm({
        reValidateMode: "onSubmit",
        validationSchema
    });

    useEffect(() => {
        const fetchCompany = async companyId => {
            const storedCompanyDocumentSnapshot = await firestore
                .collection("companies")
                .doc(companyId)
                .get();

            const storedCompany = storedCompanyDocumentSnapshot.data();
            storedCompany.id = storedCompanyDocumentSnapshot.id;

            return setCompany(storedCompany);
        };

        const initialize = async () => {
            if (props.companyId) return fetchCompany(props.companyId);

            const companyDocumentReference = firestore
                .collection("companies")
                .doc();

            return setCompany({id: companyDocumentReference.id});
        };

        initialize();
    }, [props.companyId]);

    const onSubmit = async data => {
        const mappedCompany = await mapCompany(data);
        setIsLoadingSetCompany(true);

        await firestore
            .collection("companies")
            .doc(company.id)
            .set(mappedCompany, {merge: true});

        return props.onClose();
    };

    const mapCompany = async data => {
        return {
            name: data.name,
            createAt: props.companyId ? company.createAt : new Date(),
            updateAt: props.companyId ? new Date() : null
        }
    };

    return (
        <Modal visible
               onCancel={isLoadingSetCompany ? null : props.onClose}
               footer={null}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Label required>Enter a name:</Label>
                <Controller key={`name-${get(company, "id", null)}`}
                            control={control}
                            defaultValue={get(company, "name", undefined)}
                            name="name"
                            as={
                                <Input/>
                            }/>
                {errors.name && <Error>{errors.name.message}</Error>}
                <Footer>
                    <Button key="cancel"
                            disabled={isLoadingSetCompany}
                            style={{marginRight: "0.5rem"}}
                            onClick={props.onClose}>
                        CANCEL
                    </Button>
                    <Button key="submit"
                            type="primary"
                            loading={isLoadingSetCompany}
                            htmlType="submit">
                        SAVE
                    </Button>
                </Footer>
            </form>
        </Modal>
    )
};

const Label = styled.label`
  margin: 1rem 0;
  display: block;
  ${props => props.required && `
    :before {
        display: inline-block;
        margin-right: 4px;
        color: #f5222d;
        font-size: 14px;
        font-family: SimSun,sans-serif;
        line-height: 1;
        content: "*";
    }
  `}
`;

const Error = styled.p`
  color: #f5222d;
  margin-bottom: 0;
`;

const Footer = styled.div`
  text-align: right;
  margin-top: 1.5rem;
`;
