import React, {Fragment, useEffect, useGlobal, useState} from "reactn";
import {Button, Card, Drawer, Icon, Input, List as AntList, Radio, Select} from "antd";
import {Controller, useForm} from "react-hook-form";
import styled from "styled-components";
import {array, number, object, string} from "yup";
import {Tour} from "../../../refunds/_refundId/Tour";
import {ListHeader, ListRow} from "../../../../components/complexList";
import {assign, get} from "lodash";
import uuid from "uuid";

export const AddSale = props => {
    const {onOpen, onClose} = props;

    const billSchema = object({total: number().default(0)});

    const validationSchema = object().shape({
        firstName: string().required().nullable(),
        lastName: string().required().nullable(),
        numberOfPerson: number().integer().positive().required().nullable(),
        totalPrice: object({
            total: number().positive().required(),
            currency: string().required(),
        }),
        discount: object({
            total: number().notRequired().default(0),
            currency: string().notRequired(),
        }),
        card: number().nullable(),
        hows: array().required(),
        hostel: string().nullable(),
        saleNote: string().nullable(),
        paidDollars: billSchema,
        paid: billSchema,
        changeDollars: billSchema,
        change: billSchema,
    });

    const {watch, control, errors, handleSubmit} = useForm({
        validationSchema,
        reValidateMode: "onSubmit"
    });

    const [globalHows] = useGlobal("hows");
    const [globalHostels] = useGlobal("hostels");
    const [isVisibleAddSaleDrawer, setIsVisibleAddSaleDrawer] = useState(false);
    const [tours, setTours] = useState([]);
    const [sale, setSale] = useState(null);
    const [isAddingTour, setIsAddingTour] = useState(false);

    const numberOfPerson = watch("numberOfPerson") || get(sale, "numberOfPerson", 0);

    useEffect(() => {
        return isVisibleAddSaleDrawer ? onOpen() : onClose();
    }, [isVisibleAddSaleDrawer, onOpen, onClose]);

    useEffect(() => {
        setSale(props.sale);
        setTours(get(props, "sale.tours", []));
        setIsVisibleAddSaleDrawer(!!props.sale);
    }, [props.sale]);

    const addTour = tour => setTours(prevTours => [...prevTours, tour]);

    const deleteTour = tourIndex => setTours(prevTours => prevTours.filter((mappedTour, index) => index !== tourIndex));

    const onSubmit = data => {
        if (!isAddingTour) {
            sale ? props.editSale(mappedSale(data)) : props.addSale(mappedSale(data));
            setTours([]);
            return setIsVisibleAddSaleDrawer(false);
        }
    };

    const mappedSale = data => {
        const hostel = globalHostels.find(globalHostel => globalHostel.id === data.hostel);
        const hows = data.hows.map(how => globalHows.find(globalHow => globalHow.id === how));

        return assign({}, sale, {
            id: get(sale, "id", null) || uuid(),
            createAt: get(sale, "createAt", null) || new Date(),
            firstName: data.firstName,
            lastName: data.lastName,
            numberOfPerson: data.numberOfPerson,
            totalPrice: data.totalPrice,
            card: {
                currency: "$",
                subTotal: data.card,
                total: data.card * 1.05
            },
            discount: data.discount,
            hows,
            hostel: hostel || null,
            saleNote: data.saleNote,
            tours,
            paid: data.paid,
            paidDollars: data.paidDollars,
            change: data.change,
            changeDollars: data.changeDollars
        })
    };

    const totalPrice = tours
        .reduce((accumulatorTour, mappedTour) => accumulatorTour + get(mappedTour, "tour.price", 0) +
            mappedTour.extras.reduce((accumulatorExtra, mappedExtra) => accumulatorExtra + get(mappedExtra, "price", 0), 0), 0);

    const totalPriceExtras = (extras) => extras?.reduce((accumulator, mappedExtra) => accumulator + get(mappedExtra, "price", 0), 0);

    return (
        <Fragment>
            <Button type="primary"
                    style={{marginLeft: "auto", display: "block"}}
                    onClick={() => setIsVisibleAddSaleDrawer(true)}
                    icon="plus">
                ADD SALE
            </Button>
            {
                isVisibleAddSaleDrawer &&
                <Drawer title="Add Sale"
                        placement="right"
                        width={720}
                        onClose={() => {
                            setIsVisibleAddSaleDrawer(false);
                            setTours([]);
                        }}
                        visible={isVisibleAddSaleDrawer}
                        closable>
                    <form onSubmit={handleSubmit(onSubmit)}
                          style={{paddingBottom: "4rem"}}>
                        <Label required>
                            First name
                        </Label>
                        <Controller key={`firstName-${get(sale, "id", null)}`}
                                    name="firstName"
                                    defaultValue={get(sale, "firstName", null)}
                                    control={control}
                                    as={
                                        <Input/>
                                    }/>
                        {errors.firstName && <Error>{errors.firstName.message}</Error>}
                        <Label required>
                            Last name
                        </Label>
                        <Controller key={`lastName-${get(sale, "id", null)}`}
                                    name="lastName"
                                    defaultValue={get(sale, "lastName", null)}
                                    control={control}
                                    as={
                                        <Input/>
                                    }/>
                        {errors.lastName && <Error>{errors.lastName.message}</Error>}
                        <Label required>
                            Number of people
                        </Label>
                        <Controller key={`numberOfPerson-${get(sale, "id", null)}`}
                                    name="numberOfPerson"
                                    defaultValue={get(sale, "numberOfPerson", null)}
                                    control={control}
                                    as={
                                        <Input/>
                                    }/>
                        {errors.numberOfPerson && <Error>{errors.numberOfPerson.message}</Error>}
                        <Tour addTour={addTour}
                              required
                              onOpen={() => setIsAddingTour(true)}
                              onClose={() => setIsAddingTour(false)}/>
                        {
                            tours.length > 0 &&
                            <Fragment>
                                <List bordered
                                      header={
                                          <StyledListHeader>
                                              <p>COMPANY</p>
                                              <p>TOUR</p>
                                              <p>PRICE</p>
                                              <p>ACTION</p>
                                          </StyledListHeader>
                                      }
                                      footer={
                                          <b>
                                              Total price: {numberOfPerson} x $ {totalPrice} =
                                              $ {totalPrice * numberOfPerson}
                                          </b>
                                      }
                                >
                                    {
                                        tours.map((mappedTour, index) =>
                                            <List.Item key={index}>
                                                <StyledListRow>
                                                    <p>{mappedTour.company.name}</p>
                                                    <p>{mappedTour.tour.name} {mappedTour.extras.length > 0 && <>
                                                        <p><strong>Extras:</strong></p>
                                                        {
                                                            mappedTour.extras.map(extra => <p>* {extra.name}</p>)
                                                        }
                                                    </>}</p>
                                                    <p>$ {mappedTour.tour.price + totalPriceExtras(mappedTour?.extras)}</p>
                                                    <p style={{color: "red", fontSize: "18px"}}>
                                                        <Icon type="delete"
                                                              onClick={() => deleteTour(index)}/>
                                                    </p>
                                                </StyledListRow>
                                            </List.Item>
                                        )
                                    }
                                </List>
                            </Fragment>
                        }
                        <Controller
                            key={`total-price-${get(sale, "id", null)}`}
                            defaultValue={get(sale, "totalPrice", {currency: "$"})}
                            name="totalPrice"
                            control={control}
                            as={
                                <InputCurrency label="Total Price" required helperText={errors.totalPrice}/>
                            }/>
                        <Controller
                            key={`discount-${get(sale, "id", null)}`}
                            defaultValue={get(sale, "discount", {currency: "$"})}
                            name="discount"
                            control={control}
                            as={
                                <InputCurrency label="Discount" helperText={errors.discount}/>
                            }/>
                        <br/>
                        <Card title="Paid by">
                            <Label>
                                Card
                            </Label>
                            <Controller key={`card-${get(sale, "id", null)}`}
                                        name="card"
                                        defaultValue={get(sale, "card.total", 0) / 1.05}
                                        control={control}
                                        as={
                                            <Input type="number"
                                                   addonAfter="$"/>
                                        }/>
                            {errors.card && <Error>{errors.card.message}</Error>}
                            <b>Credit card payment +5% =
                                $ {(watch("card") ? (watch("card") * 1.05) : get(props, "sale.card.total", 0)).toFixed(2)}</b>

                            <Controller
                                key={`paid-dollars-${get(sale, "id", null)}`}
                                defaultValue={get(sale, "paidDollars.total", undefined)}
                                name="paidDollars.total"
                                control={control}
                                as={<InputBill label="Dollars" currency="$"/>}
                            />
                            <Controller
                                key={`paid-${get(sale, "id", null)}`}
                                defaultValue={get(sale, "paid.total", undefined)}
                                name="paid.total"
                                control={control}
                                as={<InputBill label="Soles" currency="S/"/>}
                            />
                        </Card>
                        <br/>
                        <Card title="Change">
                            <Controller
                                key={`change-dollars-${get(sale, "id", null)}`}
                                defaultValue={get(sale, "changeDollars.total", undefined)}
                                name="changeDollars.total"
                                control={control}
                                as={<InputBill label="Dollars" currency="$"/>}
                            />
                            <Controller
                                key={`change-${get(sale, "id", null)}`}
                                defaultValue={get(sale, "change.total", undefined)}
                                name="change.total"
                                control={control}
                                as={<InputBill label="Soles" currency="S/"/>}
                            />
                        </Card>
                        <Label required>
                            How did you find us?
                        </Label>
                        <Controller key={`hows-${get(sale, "id", null)}`}
                                    name="hows"
                                    defaultValue={get(sale, "hows", []).map(how => how.id)}
                                    control={control}
                                    as={
                                        <Select showSearch
                                                allowClear
                                                mode="multiple"
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }>
                                            {
                                                globalHows
                                                    .filter(globalHow => globalHow.groupId === props.tic.groupId)
                                                    .map(globalHow =>
                                                        <Select.Option key={globalHow.id}
                                                                       value={globalHow.id}>
                                                            {globalHow.name}
                                                        </Select.Option>
                                                    )
                                            }
                                        </Select>
                                    }/>
                        {errors.hows && <Error>{errors.hows.message}</Error>}
                        <Label>
                            Hostels
                        </Label>
                        <Controller key={`hostel-${get(sale, "id", null)}`}
                                    name="hostel"
                                    defaultValue={get(sale, "hostel.id", null)}
                                    control={control}
                                    as={
                                        <Select showSearch
                                                allowClear
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }>
                                            {
                                                globalHostels
                                                    .filter(globalHostel => globalHostel.groupId === props.tic.groupId)
                                                    .map(globalHostel =>
                                                        <Select.Option key={globalHostel.id}
                                                                       value={globalHostel.id}>
                                                            {globalHostel.name}
                                                        </Select.Option>
                                                    )
                                            }
                                        </Select>
                                    }/>
                        {errors.hostel && <Error>{errors.hostel.message}</Error>}
                        <Label>
                            Note
                        </Label>
                        <Controller key={`saleNote-${get(sale, "id", null)}`}
                                    name="saleNote"
                                    defaultValue={get(sale, "saleNote", null)}
                                    control={control}
                                    as={
                                        <Input.TextArea autoSize={{minRows: 4}}/>
                                    }/>
                        {errors.saleNote && <Error>{errors.saleNote.message}</Error>}
                        <Footer>
                            <Button onClick={() => {
                                setIsVisibleAddSaleDrawer(false);
                                setTours([]);
                            }}>
                                CANCEL
                            </Button>
                            <Button type="primary"
                                    disabled={!tours.length}
                                    htmlType="submit">
                                SAVE
                            </Button>
                        </Footer>
                    </form>
                </Drawer>
            }
        </Fragment>
    )
};

const InputBill = ({value, label, currency, onChange}) => {
    return (
        <>
            <Label>
                {label}
            </Label>
            <Input
                value={value}
                addonBefore={currency}
                onChange={onChange}/>

        </>
    )
}

const InputCurrency = ({value, label, onChange, required = false, helperText}) => {
    const onChangeTotal = (event) => {
        onChange({...value, total: event.target.value})
    }

    const onChangeCurrency = (event) => {
        onChange({...value, currency: event.target.value})
    }

    return (
        <InputCurrencyContainer>
            <Label required={required}>
                {label}
            </Label>
            <div className="currency-value">
                <Input type="number" value={value.total} onChange={onChangeTotal}/>
                <Radio.Group value={value.currency} onChange={onChangeCurrency} options={[
                    {
                        value: "$",
                        label: "$"
                    },
                    {
                        value: "S/",
                        label: "S/"
                    }
                ]}/>
            </div>
            {get(helperText, "total") && <Error>{helperText.total.message}</Error>}
            {get(helperText, "currency") && <Error>{helperText.currency.message}</Error>}
        </InputCurrencyContainer>)
}

const Label = styled.label`
  margin: 1rem 0;
  display: block;
  ${props => props.required && `
    :before {
        display: inline-block;
        margin-right: 4px;
        color: #f5222d;
        font-size: 14px;
        font-family: SimSun,sans-serif;
        line-height: 1;
        content: "*";
    }
  `}
`;

const Error = styled.p`
  color: #f5222d;
  margin-bottom: 0;
`;

const List = styled(AntList)`
  margin-top: 1rem;
`;

const StyledListHeader = styled(ListHeader)`
  grid-template-columns: 2fr 3fr 1fr 1fr;
`;

const StyledListRow = styled(ListRow)`
  grid-template-columns: 2fr 3fr 1fr 1fr;

  p {
    margin: 0 !important;
  }
`;

const InputCurrencyContainer = styled.div`
  width: 100%;

  .currency-value {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 0.5rem;
    align-items: center;
  }
`;

const Footer = styled.div`
  border-top: #cdcdcd solid 1px;
  background-color: white;
  text-align: right;
  display: block;
  position: fixed;
  bottom: 0;
  padding: 1rem 0;
  width: 672px;

  button:first-child {
    margin-right: 0.5rem;
  }
`;
