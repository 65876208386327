import React, {Fragment, useEffect, useGlobal, useState} from "reactn";
import styled from "styled-components";
import {Button, DatePicker as AntDatePicker, Icon, Input, List as AntList, Radio} from "antd";
import moment from "moment";
import {Controller, useForm} from "react-hook-form";
import {number, object, string} from "yup";
import {assign, defaultTo, get} from "lodash";
import {useHistory, useParams} from "react-router";
import {firestore} from "../../../firebase";
import {Tour} from "./Tour";
import {ListHeader, ListRow} from "../../../components/complexList";

export const Refund = () => {
    const {ticId} = useParams();
    const history = useHistory();

    const validationSchema = object().shape({
        createAt: string().required(),
        firstName: string().required(),
        lastName: string().required(),
        numberOfPerson: number().positive().required(),
        refundPrice: number().required(),
        refundCurrency: string().required(),
        noteRefund: string()
    });

    const {control, errors, handleSubmit, watch} = useForm({
        validationSchema,
        reValidateMode: "onSubmit"
    });

    const [globalUser] = useGlobal("user");
    const [tic, setTic] = useState(null);
    const [tours, setTours] = useState([]);
    const [isAddingTour, setIsAddingTour] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        const fetchTic = async ticId => {
            const ticDocumentSnapshot = await firestore
                .collection("tics")
                .doc(ticId)
                .get();

            if (!ticDocumentSnapshot.exists) return history.push("/refunds");

            return setTic({...ticDocumentSnapshot.data(), id: ticDocumentSnapshot.id});
        };

        fetchTic(ticId);
    }, [history, ticId]);

    const onSubmit = async data => {
        if (!isAddingTour) {
            setIsSubmitting(true);

            await firestore
                .collection("refunds")
                .doc()
                .set(mappedRefund(data));

            return history.push("/refunds");
        }
    };

    const mappedRefund = data => assign({}, data, {
        createAt: moment(data.createAt).toDate(),
        user: globalUser,
        tic,
        tours
    });

    const addTour = tour => setTours(prevTours => [...prevTours, tour]);

    const deleteTour = tourIndex => setTours(prevTours => prevTours.filter((mappedTour, index) => index !== tourIndex));

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Label required>
                Time
            </Label>
            <Controller name="createAt"
                        control={control}
                        defaultValue={moment()}
                        as={
                            <DatePicker showTime
                                        format="DD/MM/YYYY hh:mm:ss"/>
                        }/>
            {errors.createAt && <Error>{errors.createAt.message}</Error>}
            <Label required>
                Customer first name
            </Label>
            <Controller name="firstName"
                        control={control}
                        as={
                            <Input/>
                        }/>
            {errors.firstName && <Error>{errors.firstName.message}</Error>}
            <Label required>
                Customer last name
            </Label>
            <Controller name="lastName"
                        control={control}
                        as={
                            <Input/>
                        }/>
            {errors.lastName && <Error>{errors.lastName.message}</Error>}
            <Label required>
                Number of people
            </Label>
            <Controller name="numberOfPerson"
                        control={control}
                        as={
                            <Input type="number"/>
                        }/>
            {errors.numberOfPerson && <Error>{errors.numberOfPerson.message}</Error>}
            <Tour addTour={addTour}
                  onOpen={() => setIsAddingTour(true)}
                  onClose={() => setIsAddingTour(false)}/>
            {
                tours.length > 0 &&
                <Fragment>
                    <List bordered
                          header={
                              <StyledListHeader>
                                  <p>COMPANY</p>
                                  <p>TOUR</p>
                                  <p>PRICE</p>
                                  <p>ACTION</p>
                              </StyledListHeader>
                          }
                          footer={
                              <b>
                                  Total price: {defaultTo(watch("numberOfPerson"), 0)} x $ {tours
                                  .reduce((accumulator, mappedTour) => accumulator + get(mappedTour, "tour.price", 0), 0)} =
                                  $ {tours
                                  .reduce((accumulator, mappedTour) => accumulator + get(mappedTour, "tour.price", 0), 0) * defaultTo(watch("numberOfPerson"), 0)}
                              </b>
                          }
                    >
                        {
                            tours.map((mappedTour, index) =>
                                <List.Item key={index}>
                                    <StyledListRow>
                                        <p>{mappedTour.company.name}</p>
                                        <p>{mappedTour.tour.name}</p>
                                        <p>$ {mappedTour.tour.price}</p>
                                        <p style={{color: "red", fontSize: "18px"}}>
                                            <Icon type="delete"
                                                  onClick={() => deleteTour(index)}/>
                                        </p>
                                    </StyledListRow>
                                </List.Item>
                            )
                        }
                    </List>
                </Fragment>
            }
            <Label required>
                Refund price
            </Label>
            <Controller name="refundPrice"
                        control={control}
                        as={
                            <Input type="number"/>
                        }/>
            {errors.refundPrice && <Error>{errors.refundPrice.message}</Error>}
            <Label required>
                Currency
            </Label>
            <Controller name="refundCurrency"
                        control={control}
                        onChange={([selected]) => selected.target.value}
                        as={
                            <Radio.Group options={[{label: "$", value: "$"}, {label: "S/", value: "S/"}]}/>
                        }/>
            {errors.refundCurrency && <Error>{errors.refundCurrency.message}</Error>}
            <Label>
                Note
            </Label>
            <Controller name="noteRefund"
                        control={control}
                        as={
                            <Input.TextArea autoSize={{minRows: 4}}/>
                        }/>
            {errors.noteRefund && <Error>{errors.noteRefund.message}</Error>}
            <Button block
                    loading={isSubmitting}
                    htmlType="submit"
                    style={{marginTop: "2rem"}}
                    type="primary">
                SUBMIT
            </Button>
        </form>
    )
};

const Label = styled.label`
  margin: 1rem 0;
  display: block;
  ${props => props.required && `
    :before {
        display: inline-block;
        margin-right: 4px;
        color: #f5222d;
        font-size: 14px;
        font-family: SimSun,sans-serif;
        line-height: 1;
        content: "*";
    }
  `}
`;

const Error = styled.p`
  color: #f5222d;
  margin-bottom: 0;
`;

const DatePicker = styled(AntDatePicker)`
  width: 100%;
`;

const List = styled(AntList)`
  margin-top: 1rem;
`;

const StyledListHeader = styled(ListHeader)`
  grid-template-columns: 2fr 3fr 1fr 1fr;
`;

const StyledListRow = styled(ListRow)`
  grid-template-columns: 2fr 3fr 1fr 1fr;
`;
