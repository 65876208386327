import React, {Fragment, useGlobal, useState} from "reactn";
import {Icon, Modal} from "antd";
import {firestore} from "../../firebase";
import {Card, DeleteButton, Description, List} from "../../components/list";
import {Tour} from "./Tour";

export const Tours = () => {
    const [globalTours] = useGlobal("tours");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [tourId, setTourId] = useState(null);

    const deleteTour = tourId => Modal
        .confirm({
            title: "Are you sure delete?",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk: () => {
                firestore
                    .collection("tours")
                    .doc(tourId)
                    .delete();
            }
        });

    const openTourModal = tourId => {
        setIsModalVisible(true);
        setTourId(tourId);
    };

    const closeTourModal = () => {
        setIsModalVisible(false);
        setTourId(null);
    };

    return (
        <Fragment>
            <Card hoverable
                  onClick={() => openTourModal()}>
                <Icon type="plus"/>
                <p>ADD TOUR</p>
            </Card>
            <List itemLayout="horizontal"
                  dataSource={globalTours}
                  renderItem={(tour, index) => (
                      <List.Item key={index}
                                 actions={[
                                     <DeleteButton onClick={() => deleteTour(tour.id)}>
                                         <Icon type="delete"/>
                                     </DeleteButton>
                                 ]}>
                          <List.Item.Meta description={
                              <Description onClick={() => openTourModal(tour.id)}>
                                  {tour.name}
                              </Description>
                          }/>
                          <span>{`${tour.currency} ${tour.price.toFixed(2)}`}</span>
                      </List.Item>
                  )}/>
            {
                isModalVisible &&
                <Tour tourId={tourId}
                      onClose={closeTourModal}/>
            }
        </Fragment>
    )
};
