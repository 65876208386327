import React from "react";
import {Button} from "antd";
import {Container} from "../components";

export const UpdateVersion = () => (
    <Container>
        <h1>
            Please refresh to get the latest version of the application.
        </h1>
        <Button type="primary"
                size="large"
                onClick={() => document.location.reload(true)}>
            REFRESH
        </Button>
    </Container>
);

