import React, {Fragment, useEffect, useGlobal, useState} from "reactn";
import {Icon, Modal} from "antd";
import {firestore} from "../../firebase";
import {Card, DeleteButton, Description, List, ListFilter} from "../../components/list";
import {How} from "./How";

export const Hows = () => {
    const [globalUser] = useGlobal("user");
    const [globalHows] = useGlobal("hows");
    const [globalGroups] = useGlobal("groups");
    const [globalGroupId, setGlobalGroupId] = useGlobal("groupId");
    const [groupId, setGroupId] = useState(globalUser.groupId);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [howId, setHowId] = useState(null);

    useEffect(() => {
        if (groupId) setGlobalGroupId(groupId);
    }, [groupId, setGlobalGroupId]);

    const deleteHow = howId => Modal
        .confirm({
            title: "Are you sure delete?",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk: () => {
                firestore
                    .collection("hows")
                    .doc(howId)
                    .delete();
            }
        });

    const openHowModal = howId => {
        setIsModalVisible(true);
        setHowId(howId);
    };

    const closeHowModal = () => {
        setIsModalVisible(false);
        setHowId(null);
    };

    return (
        <Fragment>
            <Card hoverable
                  onClick={() => openHowModal()}>
                <Icon type="plus"/>
                <p>ADD HOW</p>
            </Card>
            {
                globalUser.roleCode === "super_admin" &&
                <ListFilter placeholder="Select Group"
                            onSelect={groupId => setGroupId(groupId)}
                            defaultValue={globalGroupId || undefined}
                            filterOptions={globalGroups}
                            filterOptionOrderBy="groupName"
                            filterOptionName="groupName"/>
            }
            <List itemLayout="horizontal"
                  dataSource={globalHows.filter(globalHow => globalHow.groupId === globalGroupId)}
                  renderItem={(how, index) => (
                      <List.Item key={index}
                                 actions={[
                                     <DeleteButton onClick={() => deleteHow(how.id)}>
                                         <Icon type="delete"/>
                                     </DeleteButton>
                                 ]}>
                          <List.Item.Meta description={
                              <Description onClick={() => openHowModal(how.id)}>
                                  {how.name}
                              </Description>
                          }/>
                      </List.Item>
                  )}/>
            {
                isModalVisible &&
                <How howId={howId}
                     onClose={closeHowModal}/>
            }
        </Fragment>
    )
};
