import React, {Fragment, useState} from "reactn";
import {Button, List, Modal as AntModal, Tag} from "antd";
import {get} from "lodash";
import {ListHeader, ListRow} from "../../components/complexList";
import styled from "styled-components";

export const SaleDetails = props => {
    const {sale} = props;

    const [isVisible, setIsVisible] = useState(false);

    return (
        <Fragment>
            <Button type="primary"
                    onClick={() => setIsVisible(true)}>
                MORE
            </Button>
            {
                isVisible &&
                <Modal visible={isVisible}
                       title="Details"
                       onCancel={() => setIsVisible(false)}
                       footer={
                           <Button onClick={() => setIsVisible(false)}>
                               CANCEL
                           </Button>
                       }
                >
                    <h4>First name:</h4>
                    <p>{get(sale, "firstName", "No data")}</p>
                    <h4>Last name:</h4>
                    <p>{get(sale, "lastName", "No data")}</p>
                    <h4>Number of people:</h4>
                    <p>{get(sale, "numberOfPerson", "No data")}</p>
                    <h4>Tours:</h4>
                    <List
                        bordered
                        header={
                            <StyledListHeader>
                                <p>COMPANY</p>
                                <p>TOUR</p>
                            </StyledListHeader>
                        }>
                        {
                            get(sale, "tours", [])
                                .map(tour =>
                                    <StyledListRow>
                                        <p>{tour.company.name}</p>
                                        <p>
                                            <p>{tour.tour.name} </p> <p> {tour.extras.length > 0 && <>
                                            <p><strong>Extras:</strong></p>
                                            {
                                                tour.extras.map(extra => <p>* {extra.name}</p>)
                                            }
                                        </>}</p>
                                        </p>
                                    </StyledListRow>
                                )
                        }
                    </List>
                    <h4 style={{marginTop: "14px"}}>Card:</h4>
                    <p>{get(sale, "card.currency", "No data")} {get(sale, "card.total", 0).toFixed(2)}</p>
                    <h4>Total price:</h4>
                    <p>{get(sale, "totalPrice.currency", "No data")} {get(sale, "totalPrice.total", 0).toFixed(2)}</p>
                    <h4>Discount:</h4>
                    <p>{get(sale, "discount.currency", "")} {get(sale, "discount.total", 0).toFixed(2)}</p>
                    <h4>Paid soles:</h4>
                    <p>S/ {get(sale, "paid.total", 0).toFixed(2)}</p>
                    <h4>Paid dollars:</h4>
                    <p>$ {get(sale, "paidDollars.total", 0).toFixed(2)}</p>
                    <h4>Change soles:</h4>
                    <p>S/ {get(sale, "change.total", 0).toFixed(2)}</p>
                    <h4>Change dollars:</h4>
                    <p>$ {get(sale, "changeDollars.total", 0).toFixed(2)}</p>
                    <h4>How did you find us?:</h4>
                    {
                        get(sale, "hows", [])
                            .map(how =>
                                <Tag key={how.id}
                                     style={{display: "block", width: "fit-content", marginBottom: "0.5rem"}}>
                                    {how.name}
                                </Tag>
                            )
                    }
                    <h4 style={{marginTop: "7px"}}>Hostel:</h4>
                    <p>{get(sale, "hostel.name", "No data")}</p>
                    <h4>Note:</h4>
                    <p>{get(sale, "saleNote", "No data")}</p>
                </Modal>
            }
        </Fragment>
    )
};

const StyledListHeader = styled(ListHeader)`
  grid-template-columns: 1fr 1fr;
`;

const StyledListRow = styled(ListRow)`
  grid-template-columns: 1fr 1fr;
  padding: 12px 24px;
`;

const Modal = styled(AntModal)`
  h4 {
    font-weight: bold;
  }
`;
