import React, {useEffect, useGlobal, useState} from "reactn";
import {BrowserRouter} from 'react-router-dom';
import {Routes} from "./routes";
import {firestore} from "./firebase";
import {querySnapshotToArray, spinLoader} from "./utils";
import {withAuthentication} from "./hoc";
import {orderBy} from "lodash";
import titleize from "titleize";

const App = () => {
    const [globalUser] = useGlobal("user");

    const [, setGlobalHows] = useGlobal("hows");
    const [, setGlobalTics] = useGlobal("tics");
    const [, setGlobalUsers] = useGlobal("users");
    const [, setGlobalTours] = useGlobal("tours");
    const [, setGlobalExtras] = useGlobal("extras");
    const [, setGlobalHostels] = useGlobal("hostels");
    const [, setGlobalPlaces] = useGlobal("places");
    const [, setGlobalCompanies] = useGlobal("companies");
    const [, setGlobalGroups] = useGlobal("groups");
    const [, setGlobalInformationTypes] = useGlobal("informationTypes");

    const [isLoadingHows, setIsLoadingHows] = useState(true);
    const [isLoadingTics, setIsLoadingTics] = useState(true);
    const [isLoadingUsers, setIsLoadingUsers] = useState(true);
    const [isLoadingTours, setIsLoadingTours] = useState(true);
    const [isLoadingExtras, setIsLoadingExtras] = useState(true);
    const [isLoadingHostels, setIsLoadingHostels] = useState(true);
    const [isLoadingPlaces, setIsLoadingPlaces] = useState(true);
    const [isLoadingCompanies, setIsLoadingCompanies] = useState(true);
    const [isLoadingGroups, setIsLoadingGroups] = useState(true);
    const [isLoadingInformationTypes, setIsLoadingInformationTypes] = useState(true);

    useEffect(() => {
        if(globalUser) {
            const unsubscribeHows = firestore
                .collection("hows")
                .onSnapshot(async snapshot => {
                    const howsArray = orderBy(querySnapshotToArray(snapshot), how => titleize(how.name));

                    await setGlobalHows(howsArray);
                    setIsLoadingHows(false);
                });

            const unsubscribeTics = firestore
                .collection("tics")
                .onSnapshot(async snapshot => {
                    const ticsArray = orderBy(querySnapshotToArray(snapshot), tic => titleize(tic.name));

                    await setGlobalTics(ticsArray);
                    setIsLoadingTics(false);
                });

            const unsubscribeUsers = firestore
                .collection("users")
                .onSnapshot(async snapshot => {
                    const usersArray = orderBy(querySnapshotToArray(snapshot), user => titleize(user.email));

                    await setGlobalUsers(usersArray);
                    setIsLoadingUsers(false);
                });

            const unsubscribeTours = firestore
                .collection("tours")
                .onSnapshot(async snapshot => {
                    const toursArray = orderBy(querySnapshotToArray(snapshot), tour => titleize(tour.name));

                    await setGlobalTours(toursArray);
                    setIsLoadingTours(false);
                });

            const unsubscribeExtras = firestore
                .collection("extras")
                .onSnapshot(async snapshot => {
                    const extrasArray = orderBy(querySnapshotToArray(snapshot), extra => titleize(extra.name));

                    await setGlobalExtras(extrasArray);
                    setIsLoadingExtras(false);
                });

            const unsubscribeHostels = firestore
                .collection("hostels")
                .onSnapshot(async snapshot => {
                    const hostelsArray = orderBy(querySnapshotToArray(snapshot), hostel => titleize(hostel.name));

                    await setGlobalHostels(hostelsArray);
                    setIsLoadingHostels(false);
                });

            const unsubscribePlaces = firestore
                .collection("places")
                .onSnapshot(async snapshot => {
                    const placesArray = orderBy(querySnapshotToArray(snapshot), place => titleize(place.name));

                    await setGlobalPlaces(placesArray);
                    setIsLoadingPlaces(false);
                });

            const unsubscribeCompanies = firestore
                .collection("companies")
                .onSnapshot(async snapshot => {
                    const companiesArray = orderBy(querySnapshotToArray(snapshot), company => titleize(company.name));

                    await setGlobalCompanies(companiesArray);
                    setIsLoadingCompanies(false);
                });

            const unsubscribeGroups = firestore
                .collection("groups")
                .onSnapshot(async snapshot => {
                    const groupsArray = orderBy(querySnapshotToArray(snapshot), group => titleize(group.groupName));

                    await setGlobalGroups(groupsArray);
                    setIsLoadingGroups(false);
                });

            const unsubscribeInformationTypes = firestore
                .collection("informationTypes")
                .onSnapshot(async snapshot => {
                    const informationTypesArray = orderBy(querySnapshotToArray(snapshot), informationType => titleize(informationType.name));

                    await setGlobalInformationTypes(informationTypesArray);
                    setIsLoadingInformationTypes(false);
                });

            return () => {
                unsubscribeHows();
                unsubscribeTics();
                unsubscribeUsers();
                unsubscribeTours();
                unsubscribeExtras();
                unsubscribeHostels();
                unsubscribePlaces();
                unsubscribeCompanies();
                unsubscribeGroups();
                unsubscribeInformationTypes();
            }
        }
    }, [globalUser, setGlobalHows, setGlobalTics, setGlobalUsers, setGlobalTours, setGlobalExtras, setGlobalHostels, setGlobalPlaces, setGlobalCompanies, setGlobalGroups, setGlobalInformationTypes]);

    const isLoadingApp = (isLoadingHows || isLoadingTics || isLoadingUsers || isLoadingTours || isLoadingExtras || isLoadingHostels || isLoadingPlaces || isLoadingCompanies || isLoadingGroups || isLoadingInformationTypes) && globalUser;

    return isLoadingApp
        ? spinLoader()
        : (
            <BrowserRouter>
                <Routes/>
            </BrowserRouter>
        )
};

export default withAuthentication(App);
