import React from "react";
import {Icon, Spin} from "antd";
import styled from "styled-components";

const antIcon = <Icon type="loading"
                      className="spin-version-icon"
                      spin/>;

export const spinLoader = () => (
    <SpinnerLayout>
        <Spin indicator={antIcon}
              spinning={true}>
        </Spin>
    </SpinnerLayout>
);

const SpinnerLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  
  svg {
    font-size: 60px !important;
  }
`;
