import React, {Fragment, useEffect, useGlobal, useState} from "reactn";
import {Icon, List, Select} from "antd";
import {Button} from "../../components/complexList";
import {useHistory} from "react-router";
import {Description} from "../../components/list";
import styled from "styled-components";
import {isEmpty} from "lodash"

export const FreeWalkingTourForms = () => {
    const [globalUser] = useGlobal("user");
    const [globalGroups] = useGlobal("groups");
    const [globalPlaces] = useGlobal("places");
    const [globalGroupId, setGlobalGroupId] = useGlobal("groupId");
    const [groupId, setGroupId] = useState(globalUser.roleCode === "super_admin" ? undefined : globalUser.groupId);

    const history = useHistory();

    useEffect(() => {
        if (groupId) setGlobalGroupId(groupId);
    }, [groupId, setGlobalGroupId]);

    const viewPlaces = globalPlaces.filter(globalPlace => globalPlace.groupId === globalGroupId);

    return (
        <Fragment>
            <Button type="primary"
                    onClick={() => history.push(`/groups/${globalGroupId}/free-walking-tour-forms/new`)}
                    disabled={!globalGroupId}>
                <Icon type="plus"/>
                <p>ADD PERSON</p>
            </Button>
            {
                globalUser.roleCode === "super_admin" &&
                <Filter>
                    <Select value={globalGroupId || undefined}
                            placeholder="Select group"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            onSelect={setGroupId}
                            showSearch>
                        {
                            globalGroups
                                .map(globalGroup =>
                                    <Select.Option key={globalGroup.id}
                                                   value={globalGroup.id}>
                                        {globalGroup.groupName}
                                    </Select.Option>
                                )
                        }
                    </Select>
                </Filter>
            }
            {
                !isEmpty(viewPlaces) &&
                <>
                    <Header>
                        <h1>Places</h1>
                    </Header>
                    <List itemLayout="horizontal"
                          dataSource={viewPlaces}
                          renderItem={(place, index) => (
                              <List.Item key={index}>
                                  <List.Item.Meta description={
                                      <Description
                                          onClick={() => history.push(`/groups/${globalGroupId}/free-walking-tour-forms/places/${place.id}`)}>
                                          {place.name}
                                      </Description>
                                  }/>
                              </List.Item>
                          )}/>
                </>
            }
        </Fragment>
    )
};

const Filter = styled.div`
  margin-top: 1rem;

  .ant-select {
    width: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;

  h1 {
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 0;
  }
`;