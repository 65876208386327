import React, {useGlobal, useState} from "reactn";
import {Button, Input, Modal, Select} from "antd";
import {Controller, useForm} from "react-hook-form";
import {object, string} from "yup";
import styled from "styled-components";
import {firestore} from "../../firebase";
import {Fragment, useEffect} from "react";
import {get} from "lodash";

export const Hostel = props => {
    const [globalUser] = useGlobal("user");
    const [globalGroups] = useGlobal("groups");
    const [globalGroupId] = useGlobal("groupId");
    const [isLoadingSetHostel, setIsLoadingSetHostel] = useState(false);
    const [hostel, setHostel] = useState(null);

    const validationSchema = object().shape({
        name: string().required(),
        groupId: globalUser.roleCode === "super_admin" && string().required()
    });

    const {control, handleSubmit, errors} = useForm({
        reValidateMode: "onSubmit",
        validationSchema
    });

    useEffect(() => {
        const fetchHostel = async hostelId => {
            const storedHostelDocumentSnapshot = await firestore
                .collection("hostels")
                .doc(hostelId)
                .get();

            const storedHostel = storedHostelDocumentSnapshot.data();
            storedHostel.id = storedHostelDocumentSnapshot.id;

            return setHostel(storedHostel);
        };

        const initialize = async () => {
            if (props.hostelId) return fetchHostel(props.hostelId);

            const hostelDocumentReference = firestore
                .collection("hostels")
                .doc();

            return setHostel({id: hostelDocumentReference.id});
        };

        initialize();
    }, [props.hostelId]);

    const onSubmit = async data => {
        const mappedHostel = await mapHostel(data);
        setIsLoadingSetHostel(true);

        await firestore
            .collection("hostels")
            .doc(hostel.id)
            .set(mappedHostel, {merge: true});

        return props.onClose();
    };

    const mapHostel = async data => ({
        groupId: data.groupId || globalUser.groupId,
        name: data.name,
        createAt: props.hostelId ? hostel.createAt : new Date(),
        updateAt: props.hostelId ? new Date() : null
    });

    return (
        <Modal visible
               onCancel={isLoadingSetHostel ? null : props.onClose}
               footer={null}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Label required>Enter a name:</Label>
                <Controller key={`name-${get(hostel, "id", null)}`}
                            control={control}
                            defaultValue={get(hostel, "name", undefined)}
                            name="name"
                            as={
                                <Input/>
                            }/>
                {errors.name && <Error>{errors.name.message}</Error>}
                {
                    globalUser.roleCode === "super_admin" &&
                    <Fragment>
                        <Label required>Select group:</Label>
                        <Controller key={`groupId-${get(hostel, "id", null)}`}
                                    control={control}
                                    defaultValue={get(hostel, "groupId", globalGroupId)}
                                    name="groupId"
                                    as={
                                        <Select>
                                            {
                                                globalGroups.map(globalGroup =>
                                                    <Select.Option key={globalGroup.id}
                                                                   value={globalGroup.id}>
                                                        {globalGroup.groupName}
                                                    </Select.Option>)
                                            }
                                        </Select>
                                    }/>
                        {errors.groupId && <Error>{errors.groupId.message}</Error>}
                    </Fragment>
                }
                <Footer>
                    <Button key="cancel"
                            disabled={isLoadingSetHostel}
                            style={{marginRight: "0.5rem"}}
                            onClick={props.onClose}>
                        CANCEL
                    </Button>
                    <Button key="submit"
                            type="primary"
                            loading={isLoadingSetHostel}
                            htmlType="submit">
                        SAVE
                    </Button>
                </Footer>
            </form>
        </Modal>
    )
};

const Label = styled.label`
  margin: 1rem 0;
  display: block;
  ${props => props.required && `
    :before {
        display: inline-block;
        margin-right: 4px;
        color: #f5222d;
        font-size: 14px;
        font-family: SimSun,sans-serif;
        line-height: 1;
        content: "*";
    }
  `}
`;

const Error = styled.p`
  color: #f5222d;
  margin-bottom: 0;
`;

const Footer = styled.div`
  text-align: right;
  margin-top: 1.5rem;
`;
