import React, {useEffect, useGlobal, useMemo} from "reactn";
import {Avatar as AntAvatar, Button, Card as AntCard, Icon, Input as AntInput, notification} from "antd";
import styled from "styled-components";
import {Controller, useForm} from "react-hook-form";
import {object, string} from "yup";
import {isEmpty, map} from "lodash";
import {auth} from "../../firebase";
import {firebaseAuthenticationErrors} from "../../firebase/auth";
import {useHistory} from "react-router";

const {Password: AntPassword} = AntInput;

export const Login = () => {
    const validationSchema = object().shape({
        email: string().email().required(),
        password: string().required()
    });

    const [globalUser] = useGlobal("user");
    const [globalIsLoadingUser, setGlobalIsLoadingUser] = useGlobal("isLoadingUser");
    const {handleSubmit, control, errors} = useForm({
        validationSchema,
        reValidateMode: "onSubmit"
    });

    const history = useHistory();

    useMemo(() => {
        if (globalUser) return history.push("/client-forms");
    }, [globalUser, history]);

    useEffect(() => {
        if (!isEmpty(errors)) {
            notification["error"]({
                message: "Login error",
                description: map(errors, error => error.message)[0]
            });
        }
    }, [errors]);

    const onSubmitLogin = async data => {
        try {
            await setGlobalIsLoadingUser(true);
            await auth.signInWithEmailAndPassword(data.email, data.password);
        } catch (error) {
            await onLoginError(error);
        }
        return history.push("/client-forms");
    };

    const onLoginError = async (error) => {
        const errorMessage = firebaseAuthenticationErrors[error.code];

        await setGlobalIsLoadingUser(false);

        notification["error"]({
            message: "Login error",
            description: errorMessage
        });
    };

    return (
        <LoginContainer>
            <Card>
                <h1>
                    Tourist Information Center
                </h1>
                <Avatar size={100}
                        icon="team"/>
                <form onSubmit={handleSubmit(onSubmitLogin)}
                      noValidate>
                    <Controller control={control}
                                name="email"
                                as={
                                    <Input placeholder="Enter your email"
                                           type="email"
                                           error={errors.email}
                                           prefix={<InputIcon type="user"/>}/>
                                }/>
                    <Controller control={control}
                                name="password"
                                as={
                                    <Password placeholder="Enter your password"
                                              error={errors.password}
                                              prefix={<InputIcon type="lock"/>}/>
                                }/>
                    <Button type="primary"
                            loading={globalIsLoadingUser}
                            block
                            htmlType="submit">
                        LOGIN
                    </Button>
                </form>
            </Card>
        </LoginContainer>
    );
};

const LoginContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(90deg,#ec2f4b,#009fff);
`;

const Avatar = styled(AntAvatar)`
  background-color: #1890ff;
  margin-bottom: 20px;
`;

const Card = styled(AntCard)`
  max-width: 250px;
  text-align: center;
  
  h1 {
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  @media screen and (min-width: 768px) {
    max-width: 400px;
  }
`;

const Input = styled(AntInput)`
  margin-bottom: 2rem;
  
  .ant-input {
    border-color: ${props => props.error ? "red" : "rgb(217, 217, 217)"} !important;
  }
`;

const Password = styled(AntPassword)`
  margin-bottom: 2rem;
  
  .ant-input {
    border-color: ${props => props.error ? "red" : "rgb(217, 217, 217)"} !important;
  }
`;

const InputIcon = styled(Icon)`
  color: rgba(0, 0, 0, 0.25);
`;
