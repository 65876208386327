import React from "react";
import {Route, Switch} from "react-router-dom";
import {Redirect} from "react-router";
import {PrivateRoute} from "./PrivateRoute";
import {BaseLayout} from "../components";
import {
    ClientForm,
    ClientForms,
    Companies,
    FreeWalkingTourForm,
    FreeWalkingTourForms,
    PlaceFreeWalkingTourForms,
    Groups,
    Hostels,
    Places,
    Hows,
    InformationTypes,
    Login,
    PrintClientForms,
    PrintFreeWalkingTourForms,
    Refund,
    Refunds,
    Tics,
    Tours,
    Extras,
    Users
} from "../pages"
import {GroupAdminPrivateRoute} from "./GroupAdminPrivateRoute";
import {SuperAdminPrivateRoute} from "./SuperAdminPrivateRoute";
import {SellerPrivateRoute} from "./SellerPrivateRoute";

export const Routes = () => (
    <Switch>
        <Route exact
               path="/login"
               render={() => <Login/>}
        />
        <SellerPrivateRoute exact
                            path="/client-forms">
            <BaseLayout>
                <ClientForms/>
            </BaseLayout>
        </SellerPrivateRoute>
        <PrivateRoute exact
                      path="/tics/:ticId/client-forms/:clientFormId">
            <BaseLayout>
                <ClientForm/>
            </BaseLayout>
        </PrivateRoute>
        <PrivateRoute exact
                      path="/tics/:ticId/client-forms/:entryDate/print">
            <PrintClientForms/>
        </PrivateRoute>
        <SellerPrivateRoute exact
                            path="/free-walking-tour-forms">
            <BaseLayout>
                <FreeWalkingTourForms/>
            </BaseLayout>
        </SellerPrivateRoute>
        <PrivateRoute exact
                      path="/groups/:groupId/free-walking-tour-forms/:freeWalkingTourFormId">
            <BaseLayout>
                <FreeWalkingTourForm/>
            </BaseLayout>
        </PrivateRoute>
        <PrivateRoute exact
                      path="/groups/:groupId/free-walking-tour-forms/places/:placeId">
            <BaseLayout>
                <PlaceFreeWalkingTourForms/>
            </BaseLayout>
        </PrivateRoute>
        <SellerPrivateRoute exact
                            path="/groups/:groupId/free-walking-tour-forms/places/:placeId/:entryDate/print">
            <PrintFreeWalkingTourForms/>
        </SellerPrivateRoute>
        <GroupAdminPrivateRoute exact
                                path="/refunds">
            <BaseLayout>
                <Refunds/>
            </BaseLayout>
        </GroupAdminPrivateRoute>
        <GroupAdminPrivateRoute exact
                                path="/tics/:ticId/refunds/:refundId">
            <BaseLayout>
                <Refund/>
            </BaseLayout>
        </GroupAdminPrivateRoute>
        <GroupAdminPrivateRoute exact
                                path="/users">
            <BaseLayout>
                <Users/>
            </BaseLayout>
        </GroupAdminPrivateRoute>
        <SuperAdminPrivateRoute exact
                                path="/groups">
            <BaseLayout>
                <Groups/>
            </BaseLayout>
        </SuperAdminPrivateRoute>
        <GroupAdminPrivateRoute exact
                                path="/tics">
            <BaseLayout>
                <Tics/>
            </BaseLayout>
        </GroupAdminPrivateRoute>
        <GroupAdminPrivateRoute exact
                                path="/hostels">
            <BaseLayout>
                <Hostels/>
            </BaseLayout>
        </GroupAdminPrivateRoute>
        <GroupAdminPrivateRoute exact
                                path="/places">
            <BaseLayout>
                <Places/>
            </BaseLayout>
        </GroupAdminPrivateRoute>
        <GroupAdminPrivateRoute exact
                                path="/information-types">
            <BaseLayout>
                <InformationTypes/>
            </BaseLayout>
        </GroupAdminPrivateRoute>
        <SuperAdminPrivateRoute exact
                                path="/companies">
            <BaseLayout>
                <Companies/>
            </BaseLayout>
        </SuperAdminPrivateRoute>
        <SuperAdminPrivateRoute exact
                                path="/tours">
            <BaseLayout>
                <Tours/>
            </BaseLayout>
        </SuperAdminPrivateRoute>
        <SuperAdminPrivateRoute exact
                                path="/extras">
            <BaseLayout>
                <Extras/>
            </BaseLayout>
        </SuperAdminPrivateRoute>
        <GroupAdminPrivateRoute exact
                                path="/hows">
            <BaseLayout>
                <Hows/>
            </BaseLayout>
        </GroupAdminPrivateRoute>
        <Redirect to="/client-forms"/>
    </Switch>
);
