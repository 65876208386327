import React, {setGlobal, useEffect, useGlobal, useState} from "reactn";
import {setLocale} from "yup";
import {yup} from "../config";

export const withConfiguration = Component => () => {
    const [globalCurrentConfig] = useGlobal("currentConfig");
    const [isLoadingConfig, setIsLoadingConfig] = useState(true);

    useEffect(() => {
        const initializeConfig = async () => {
            await setGlobal({
                user: null,
                isLoadingUser: false,
                hows: [],
                tics: [],
                users: [],
                tours: [],
                extras: [],
                hostels: [],
                places: [],
                companies: [],
                groups: [],
                informationTypes: [],
                currentConfig: {},
                groupId: null,
                ticId: null,
                roleCode: null
            });

            setLocale(yup);
        };

        !globalCurrentConfig && initializeConfig();
        setIsLoadingConfig(false);
    }, [globalCurrentConfig]);

    return isLoadingConfig
        ? null
        : <Component/>
};
