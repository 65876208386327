import React, {Fragment, useEffect, useGlobal, useState} from "reactn";
import {Button as AntButton, DatePicker, Divider as AntDivider, Icon, List as AntList} from "antd";
import moment from "moment";
import {FiltersGrid} from "../../../../../../components/complexList";
import styled from "styled-components";
import {firestore} from "../../../../../../firebase";
import {querySnapshotToArray} from "../../../../../../utils";
import titleize from "titleize";
import {useHistory, useParams} from "react-router";

export const PlaceFreeWalkingTourForms = () => {
    const [globalPlaces] = useGlobal("places");
    const {groupId, placeId} = useParams();
    const [freeWalkingTourFormsEntryDate, setFreeWalkingTourFormsEntryDate] = useState(moment());
    const [freeWalkingTourForms, setFreeWalkingTourForms] = useState([]);
    const [place, setPlace] = useState(undefined);

    const history = useHistory();

    useEffect(() => {
        if (placeId) {
            setPlace(globalPlaces.find(place => place.id === placeId))
        }
    }, [placeId, setPlace]);

    useEffect(() => {
        const unsubscribeFreeWalkingTourForms = firestore
            .collection("freeWalkingTourForms")
            .where("entryDate", "==", freeWalkingTourFormsEntryDate.format("YYYY-MM-DD"))
            .where("groupId", "==", groupId || null)
            .onSnapshot(snapshot => setFreeWalkingTourForms(querySnapshotToArray(snapshot)));

        return () => unsubscribeFreeWalkingTourForms();
    }, [setFreeWalkingTourForms, groupId, freeWalkingTourFormsEntryDate]);

    const totalFreeWalkingTourFormsByLanguageAndPlace = (language, placeId) => freeWalkingTourForms
        .filter(freeWalkingTourForm => freeWalkingTourForm.language === language && freeWalkingTourForm.placeId === placeId)
        .length;

    const freeWalkingTourFormsByPlace = freeWalkingTourForms
        .filter(freeWalkingTourForm => freeWalkingTourForm.placeId === placeId);

    const deleteFreeWalkingTourForm = async freeWalkingTourFormId => await firestore
        .collection("freeWalkingTourForms")
        .doc(freeWalkingTourFormId)
        .delete();

    return (
        <Fragment>
            <FreeWalkingTourHeader>
                <h1>{place?.name || ""}</h1>
            </FreeWalkingTourHeader>
            <FiltersGrid>
                <DatePicker value={freeWalkingTourFormsEntryDate}
                            onChange={setFreeWalkingTourFormsEntryDate}
                            format="DD/MM/YYYY"/>
            </FiltersGrid>
            <List header={
                <Fragment>
                    <FreeWalkingTourHeader>
                        <AntButton type="primary"
                                   onClick={() => history.push(`/groups/${groupId}/free-walking-tour-forms/places/${placeId}/${freeWalkingTourFormsEntryDate.format("YYYY-MM-DD")}/print`)}
                                   disabled={!freeWalkingTourFormsByPlace.length}
                                   icon="printer">
                            PRINT
                        </AntButton>
                    </FreeWalkingTourHeader>
                    <Divider/>
                    <b>{totalFreeWalkingTourFormsByLanguageAndPlace("english", placeId)} English</b>
                    <b>{totalFreeWalkingTourFormsByLanguageAndPlace("spanish", placeId)} Spanish</b>
                </Fragment>
            }>
                {
                    freeWalkingTourFormsByPlace.map(freeWalkingTourForm =>
                        <List.Item key={freeWalkingTourForm.id}>
                            <Traveler>
                                <div>
                                    <Link
                                        onClick={() => history.push(`/groups/${groupId}/free-walking-tour-forms/${freeWalkingTourForm.id}`)}>
                                        {titleize(freeWalkingTourForm.firstName)} {titleize(freeWalkingTourForm.lastName)} [{freeWalkingTourForm.country}]
                                    </Link>
                                    <span style={{display: "block"}}>{titleize(freeWalkingTourForm.language)}</span>
                                    <span style={{display: "block"}}>{freeWalkingTourForm.email}</span>
                                </div>
                                <Icon style={{color: "#fe008f", fontSize: "24px"}}
                                      onClick={() => deleteFreeWalkingTourForm(freeWalkingTourForm.id)}
                                      type="delete"/>
                            </Traveler>
                        </List.Item>
                    )
                }
            </List>
        </Fragment>
    )
};

const List = styled(AntList)`
  .ant-list-header {
    b {
      display: block;
      width: 100%;
    }
  }
`;

const FreeWalkingTourHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  h1 {
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 0;
  }
`;

const Divider = styled(AntDivider)`
  margin: 0.6rem 0;
`;

const Traveler = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Link = styled.h3`
  color: #40a9ff;
  margin: 0;
  cursor: pointer;
`;
