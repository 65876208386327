import React, {Fragment, useGlobal, useState} from "reactn";
import styled from "styled-components";
import {Button, Icon, Modal, Select as AntSelect} from "antd";
import {Controller, useForm} from "react-hook-form";
import {object, string, array} from "yup";
import {useEffect} from "react";

export const Tour = props => {
    const {onOpen, onClose} = props;
    const validationSchema = object().shape({
        companyId: string().required(),
        tourId: string().required(),
        extrasIds: array(),
    });

    const {watch, control, errors, handleSubmit} = useForm({
        validationSchema,
        reValidateMode: "onSubmit"
    });

    const [isVisibleTourModal, setIsVisibleTourModal] = useState(false);
    const [globalCompanies] = useGlobal("companies");
    const [globalTours] = useGlobal("tours");
    const [globalExtras] = useGlobal("extras");

    useEffect(() => {
        return isVisibleTourModal ? onOpen() : onClose();
    }, [isVisibleTourModal, onOpen, onClose]);

    const onSubmit = data => {
        const company = globalCompanies.find(globalCompany => globalCompany.id === data.companyId);
        const tour = globalTours.find(globalTour => globalTour.id === data.tourId);
        const extras = data.extrasIds ? data.extrasIds.map(extraId => globalExtras.find(globalExtra => globalExtra.id === extraId)) : []

        const mappedTour = {
            tour,
            company,
            extras
        };

        props.addTour(mappedTour);

        return setIsVisibleTourModal(false);
    };

    return (
        <Fragment>
            <Label required={props.required}>
                Tours
            </Label>
            <Button type="primary"
                    onClick={() => setIsVisibleTourModal(true)}>
                <Icon type="plus"/>
            </Button>
            {
                isVisibleTourModal &&
                <Modal title="Add Tour"
                       footer={null}
                       onCancel={() => setIsVisibleTourModal(false)}
                       visible={isVisibleTourModal}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Label required>
                            Company
                        </Label>
                        <Controller control={control}
                                    name="companyId"
                                    as={
                                        <Select showSearch
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }>
                                            {
                                                globalCompanies.map(globalCompany =>
                                                    <Select.Option key={globalCompany.id}
                                                                   value={globalCompany.id}>
                                                        {globalCompany.name}
                                                    </Select.Option>
                                                )
                                            }
                                        </Select>
                                    }/>
                        {errors.companyId && <Error>{errors.companyId.message}</Error>}
                        <Label required>
                            Tour
                        </Label>
                        <Controller control={control}
                                    name="tourId"
                                    as={
                                        <Select showSearch
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }>
                                            {
                                                globalTours
                                                    .filter(globalTour => globalTour.company.id === watch("companyId"))
                                                    .map(globalTour =>
                                                        <Select.Option key={globalTour.id}
                                                                       value={globalTour.id}>
                                                            {globalTour.name}
                                                        </Select.Option>
                                                    )
                                            }
                                        </Select>
                                    }/>
                        {errors.tourId && <Error>{errors.tourId.message}</Error>}
                        <Label>
                            Extras
                        </Label>
                        <Controller control={control}
                                    name="extrasIds"
                                    as={
                                        <Select showSearch
                                                mode="multiple"
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }>
                                            {
                                                globalExtras
                                                    .filter(globalExtra => globalExtra.company.id === watch("companyId"))
                                                    .map(globalExtra =>
                                                        <Select.Option key={globalExtra.id}
                                                                       value={globalExtra.id}>
                                                            {globalExtra.name}
                                                        </Select.Option>
                                                    )
                                            }
                                        </Select>
                                    }/>
                        <Footer>
                            <Button style={{marginRight: "1rem"}}
                                    onClick={() => setIsVisibleTourModal(false)}>
                                CANCEL
                            </Button>
                            <Button htmlType="submit"
                                    type="primary">
                                SAVE
                            </Button>
                        </Footer>
                    </form>
                </Modal>
            }
        </Fragment>
    )
};

const Label = styled.label`
  margin: 1rem 0;
  display: block;
  ${props => props.required && `
    :before {
        display: inline-block;
        margin-right: 4px;
        color: #f5222d;
        font-size: 14px;
        font-family: SimSun,sans-serif;
        line-height: 1;
        content: "*";
    }
  `}
`;

const Select = styled(AntSelect)`
  width: 100%;
`;

const Error = styled.p`
  color: #f5222d;
  margin-bottom: 0;
`;

const Footer = styled.div`
  text-align: right;
  margin-top: 1.5rem;
`;
