import React, {Fragment, useEffect, useState} from "reactn";
import styled from "styled-components";
import {Button, Divider as AntDivider, Table as AntTable} from "antd";
import {useHistory, useParams} from "react-router";
import moment from "moment";
import {firestore} from "../../../../../../firebase";
import {querySnapshotToArray} from "../../../../../../utils";
import {get} from "lodash";
import uuid from "uuid";

export const PrintClientForms = () => {
    const {ticId, entryDate} = useParams();

    const [totalSalesAndRefunds, setTotalSalesAndRefunds] = useState([]);

    const history = useHistory();

    useEffect(() => {
        const mapDaySalesAndRefunds = data => ({
            key: uuid(),
            date: moment(data.createAt.toDate()).format("h:mm a"),
            fullName: `${data.firstName} ${data.lastName}`,
            numberOfPeople: data.refundPrice ? null : data.numberOfPerson,
            company: data.refundPrice ? null : get(data, "tours", []).map(tour => tour.company.name).join(","),
            paidDollars: data.refundPrice ? null : get(data, "paidDollars.total", null),
            paidSoles: data.refundPrice ? null : get(data, "paid.total", null),
            changeDollars: data.refundPrice ? null : get(data, "changeDollars.total", null),
            changeSoles: data.refundPrice ? null : get(data, "change.total", null),
            refundCurrency: data.refundCurrency,
            refundPrice: data.refundPrice
        });

        const fetchDaySalesAndRefunds = async () => {
            let totalSalesAndRefundsArray = [];

            const clientFormsQuerySnapshot = await firestore
                .collection("clientForms")
                .where("entryDate", ">=", moment(entryDate).startOf("day").toDate())
                .where("entryDate", "<=", moment(entryDate).endOf("day").toDate())
                .where("tic.id", "==", ticId)
                .get();

            const clientFormsArray = querySnapshotToArray(clientFormsQuerySnapshot);

            clientFormsArray
                .filter(clientForm => get(clientForm, "sales", []).length)
                .forEach(clientForm => clientForm.sales
                    .forEach(sale => {
                        if (sale.paid.total > 0 || sale.paidDollars.total > 0)
                            totalSalesAndRefundsArray.push(mapDaySalesAndRefunds(sale))
                    })
                );

            const refundsQuerySnapshot = await firestore
                .collection("refunds")
                .where("createAt", ">=", moment(entryDate, "YYYY-MM-DD").startOf("day").toDate())
                .where("createAt", "<=", moment(entryDate, "YYYY-MM-DD").endOf("day").toDate())
                .where("tic.id", "==", ticId)
                .get();

            const refundsArray = querySnapshotToArray(refundsQuerySnapshot);

            refundsArray
                .forEach(refund => totalSalesAndRefundsArray.push(mapDaySalesAndRefunds(refund)));

            const totalRefundSoles = totalSalesAndRefundsArray
                .reduce((total, totalSalesAndRefundsArrayRecord) => total + (totalSalesAndRefundsArrayRecord.refundCurrency === "S/" ? get(totalSalesAndRefundsArrayRecord, "refundPrice", 0) : 0), 0);

            const totalRefundDollars = totalSalesAndRefundsArray
                .reduce((total, totalSalesAndRefundsArrayRecord) => total + (totalSalesAndRefundsArrayRecord.refundCurrency === "$" ? get(totalSalesAndRefundsArrayRecord, "refundPrice", 0) : 0), 0);

            totalSalesAndRefundsArray.push({
                key: "total",
                date: "TOTAL:",
                paidDollars: totalSalesAndRefundsArray
                    .reduce((total, totalSalesAndRefundsArrayRecord) => total + get(totalSalesAndRefundsArrayRecord, "paidDollars", 0), 0),
                paidSoles: totalSalesAndRefundsArray
                    .reduce((total, totalSalesAndRefundsArrayRecord) => total + get(totalSalesAndRefundsArrayRecord, "paidSoles", 0), 0),
                changeDollars: totalSalesAndRefundsArray
                    .reduce((total, totalSalesAndRefundsArrayRecord) => total + get(totalSalesAndRefundsArrayRecord, "changeDollars", 0), 0),
                changeSoles: totalSalesAndRefundsArray
                    .reduce((total, totalSalesAndRefundsArrayRecord) => total + get(totalSalesAndRefundsArrayRecord, "changeSoles", 0), 0),
                totalRefundDollars,
                totalRefundSoles,
                totalRefund: `$ ${totalRefundDollars.toFixed(2)}\nS/ ${totalRefundSoles.toFixed(2)}`
            });

            setTotalSalesAndRefunds(totalSalesAndRefundsArray);

            return window.print();
        };

        fetchDaySalesAndRefunds();
    }, [entryDate, history, ticId]);

    useEffect(() => {
    }, [totalSalesAndRefunds]);

    return (
        <Content>
            <Header>
                <h1>Tourist Information Center</h1>
                <Button type="primary"
                        onClick={() => history.goBack()}>
                    BACK
                </Button>
            </Header>
            <Divider/>
            <Header>
                <h3>Day sales: <b>{moment(entryDate, "YYYY-MM-DD").format("DD/MM/YYYY")}</b></h3>
            </Header>
            <Table dataSource={
                totalSalesAndRefunds
                    .map(totalSalesAndRefund => ({
                        ...totalSalesAndRefund,
                        paidDollars: totalSalesAndRefund.paidDollars !== null ? `$ ${totalSalesAndRefund.paidDollars.toFixed(2)}` : null,
                        paidSoles: totalSalesAndRefund.paidSoles !== null ? `S/ ${totalSalesAndRefund.paidSoles.toFixed(2)}` : null,
                        changeDollars: totalSalesAndRefund.changeDollars !== null ? `$ ${totalSalesAndRefund.changeDollars.toFixed(2)}` : null,
                        changeSoles: totalSalesAndRefund.changeSoles !== null ? `S/ ${totalSalesAndRefund.changeSoles.toFixed(2)}` : null,
                        refunds: totalSalesAndRefund.totalRefund || (totalSalesAndRefund.refundPrice ? `${totalSalesAndRefund.refundCurrency} ${totalSalesAndRefund.refundPrice}` : null)
                    }))
            }
                   rowClassName={row => row.refundPrice ? "refund" : (row.totalRefund ? "total" : "")}
                   pagination={false}
                   columns={[
                       {
                           title: "Date",
                           dataIndex: "date",
                           key: "date"
                       },
                       {
                           title: "Full name",
                           dataIndex: "fullName",
                           key: "fullName"
                       },
                       {
                           title: "Number of people",
                           dataIndex: "numberOfPeople",
                           key: "numberOfPeople",
                           align: "center"
                       },
                       {
                           title: "Company",
                           dataIndex: "company",
                           key: "company"
                       },
                       {
                           title: "Pay dollars",
                           dataIndex: "paidDollars",
                           key: "paidDollars",
                           align: "right"
                       },
                       {
                           title: "Pay soles",
                           dataIndex: "paidSoles",
                           key: "paidSoles",
                           align: "right"
                       },
                       {
                           title: "Change dollars",
                           dataIndex: "changeDollars",
                           key: "changeDollars",
                           align: "right"
                       },
                       {
                           title: "Change soles",
                           dataIndex: "changeSoles",
                           key: "changeSoles",
                           align: "right"
                       },
                       {
                           title: "Refunds",
                           dataIndex: "refunds",
                           key: "refunds",
                           align: "right"
                       }
                   ]}/>
            <br/>
            {
                totalSalesAndRefunds.length &&
                <Fragment>
                    <h3>
                        <b>
                            Total
                            Dollars:
                            $ {(totalSalesAndRefunds[totalSalesAndRefunds.length - 1].paidDollars - totalSalesAndRefunds[totalSalesAndRefunds.length - 1].changeDollars - totalSalesAndRefunds[totalSalesAndRefunds.length - 1].totalRefundDollars).toFixed(2)}
                        </b>
                    </h3>
                    <h3>
                        <b>
                            Total
                            Soles:
                            S/ {(totalSalesAndRefunds[totalSalesAndRefunds.length - 1].paidSoles - totalSalesAndRefunds[totalSalesAndRefunds.length - 1].changeSoles - totalSalesAndRefunds[totalSalesAndRefunds.length - 1].totalRefundSoles).toFixed(2)}
                        </b>
                    </h3>
                </Fragment>
            }
        </Content>
    )
};

const Content = styled.section`
  padding: 20px;
  
  @media print {
    padding: 0;
  }
  
  h1 {
    font-weight: bold;
    margin-bottom: 0;
  }
  
  h3 {
    margin-bottom: 0;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  
  @media print {
    button {
      display: none;
    }
  }
`;

const Table = styled(AntTable)`
  margin-top: 1rem;
  
  .refund {
    background-color: #f9ce7e;
  }
  
  .total {
    background-color: #f4f4f4;
    font-weight: bold;
  }
  
  td {
    white-space: pre-line;
  }
  
  @media print {
    td, th {
      padding: 0.8rem 0.5rem !important;
    }
  }
`;

const Divider = styled(AntDivider)`
  @media print {
    margin: 0.5rem 0;
  }
`;
